import {
  Box,
  Collapse,
  Text,
  Flex,
  Spinner,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Editable,
  EditablePreview,
  EditableInput,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { DeleteIcon, WarningIcon } from '@chakra-ui/icons';
import { Container } from 'components/Container';
import { DataContainer } from 'components/DataContainer';
import { Icon } from 'components/Icon';
import { NumberCard } from 'components/NumberCard';
import { fetchApi } from 'lib/fetchApi';
import { ArrowDown, ArrowUp, Logout } from 'lib/IconLib';
import { useApi } from 'lib/useApi';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Order, Payment, Prediction } from 'models/ApiModels';
import { SyncVakaPin } from 'components/SyncVakaPin';
import { ContainerHead } from 'components/ContainerHead';
import { colors } from 'lib/constants';
import { StatusLabel } from 'components/StatusLabel';
import { formatUnitNum } from 'lib/formatUnitNum';
import Comments from 'components/Comments';

const headStyles = {
  paddingBottom: 4,
  fontSize: '14px',
  color: '#797B80',
  fontWeight: 500,
};
export default function OrderPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: order, reload: reloadOrder, state: orderLoadingState } = useApi<
    Order
  >(`/orders/${id}`);
  const { data: pin } = useApi<string>(`/orders/${id}/pin`);
  const { data: prediction } = useApi<Prediction>('/me/orders/predict');

  const toast = useToast();

  const [isAlertOpen, setAlertIsOpen] = React.useState(false);
  const onClose = (): void => setAlertIsOpen(false);
  const cancelRef = React.useRef(null);

  const [userEmail, setUserEmail] = React.useState("")
  useEffect(() => {
    if (order !== undefined) {
      fetchApi(`/users/${order.userId}`).then(res => res.json().then(data => setUserEmail(data.email)));
    }
  }, [order])

  async function handleOrderTermination(): Promise<boolean> {
    onClose();
    const terminated = await fetchApi(`/orders/${id}/terminate`);
    if (terminated.ok) {
      reloadOrder();
      toast({
        title: 'Ordern är avslutad!',
        status: 'success',
        position: 'top',
        isClosable: true,
      });
      return true;
    }
    return false;
  }

  async function handleForceTermination(): Promise<boolean> {
    onClose();
    const terminated = await fetchApi(`/orders/${id}/forceterminate`);
    if (terminated.ok) {
      reloadOrder();
      toast({
        title: 'Ordern är avslutad!',
        status: 'success',
        position: 'top',
        isClosable: true,
      });
      return true;
    }
    return false;
  }
  function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  async function updateNextPaymentAmount(
    amount: string,
    id: number
  ): Promise<boolean> {
    const num = Number(amount);
    if (num > 0) {
      const updated = await fetchApi(`/payments/update/${id}?amount=${num}`);
      //
      if (updated.ok) {
        toast({
          title: 'Nästkommande betalning uppdaterad',
          description: `Nästa betalning är nu ${amount} kr`,
          status: 'success',
          position: 'top',
          isClosable: true,
        });
        return true;
      }
    }
    toast({
      title: 'Kunde inte sätta pris',
      description:
        'Är du säker på att det blev rätt? Bara pris högre än 0kr är tillåtet',
      status: 'error',
      position: 'top',
      isClosable: true,
    });
    return false;
  }

  if (orderLoadingState === 'loading') return <Spinner />;
  if (!order) return <p>Något gick fel"</p>

  return (
    <>
      <ContainerHead
        backButton={true}
        title={"Orderinfo"}
        columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 5 }}>
        <DataContainer title="Id">
          {order.orderReference}
        </DataContainer>
        <DataContainer title="Status">
          <StatusLabel status={order.status} />
        </DataContainer>
        <DataContainer title="Bokad från">
          {new Date(order.createdAt).toLocaleDateString()}
        </DataContainer>
        {order.activeTo && <DataContainer title='Bokad till'>
          {order.activeTo}
        </DataContainer>}
        <DataContainer title="Anläggning">
          <Link to={`/anlaggningar/${order.unit.facility.id}`} style={{ display: 'flex' }}>
            {order.unit.facility.name}
            <Icon icon={Logout} fill="#333" />
          </Link>
        </DataContainer>
        <DataContainer title="Förrådsnummer">
          <Link
            style={{ display: 'flex' }}
            to={`/objekt/${order.unit?.id ?? ''}`}>
            {order.unit?.externalId ? formatUnitNum(order.unit.externalId) : 'Inget förråd'}
            <Icon icon={Logout} fill="#333" />
          </Link>
        </DataContainer>
        {order ? (
          <>
            <DataContainer title="Företagskund">
              {order.calculateSalesTax ? 'Ja' : 'Nej'}
            </DataContainer>
            <DataContainer title="Kundinfo">
              <Link to={`/konton/${order.userId}`} style={{ display: 'flex' }}>
                {userEmail ?? 'Inget konto'}
                <Icon icon={Logout} fill="#333" />
              </Link>
            </DataContainer>
            <DataContainer title="Månadskostnad">
              {order.monthlyAmount ?? '0'}:-
            </DataContainer>
          </>
        ) : (
          <Spinner />
        )}
      </ContainerHead>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Säg upp avtal
          </AlertDialogHeader>

          <AlertDialogBody>
            Är du säker på att du vill avsluta det här avtalet? Tvingar du
            uppsägning så är det ingen uppsägningstid eller vidare betalningar.
            {new Date(prediction?.lastPaymentAt ?? '').toLocaleDateString(
              'sv-SE'
            )}{' '}
            <br /> Kunden informeras om uppsägningen.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} variant="primaryWhite" onClick={onClose}>
              Gå tillbaka
            </Button>
            <Button onClick={handleForceTermination} ml={3} backgroundColor={colors.system.error[900]} color="white">
              Tvinga uppsägning
            </Button>
            <Button
              variant="primary"
              onClick={handleOrderTermination}
              ml={3}>
              Säg upp
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Container>
        <Flex direction="row" mb={8}>
          <Box mr="10" flexDir="column">
            <SyncVakaPin orderId={order.id} />
          </Box>
          <Button variant="primaryWhite" px={5} onClick={(): void => {
            setAlertIsOpen(true);
          }}><DeleteIcon mr={3} /> Säg upp avtal</Button>
          {/* <IconCard
            title="Säg upp"
            icon={userIcon}
            desc="Säg upp avtal"
            onClick={(): void => {
              setAlertIsOpen(true);
            }}
          /> */}
        </Flex>
        <Flex display="flex" flexDir="column" width="100%">
          <Flex display="flex" flexDir={{ base: "column", lg: "row" }} >
            <Box mr="10" minW="450px"
              maxW="min-content">
              <Text fontWeight="700" fontFamily="Montserrat" mb={3}>Förrådskod</Text>
              <NumberCard
                backgroundColor="#F6F6F6"
                number={pin ? parseInt(pin) : 999999}
                fontSize={80}
                mb={8}
                minW="fit-content"
                maxW="min-content"
              />
            </Box>
            <Flex
              display="flex"
              align="left"
              flexDir="column"
              flexGrow="1"
              maxW="1000px"
              height="auto">
              {order.payments ? (
                <>
                  <PaymentsDrawer title="Genomförda Betalningar">
                    <RenderCaptures captures={order.payments} />
                  </PaymentsDrawer>

                  {order ? (
                    <PaymentsDrawer title="Kommande Betalningar">
                      {order.payments.map((payment: Payment) => {
                        if (payment.capturedAt) return null;
                        if (
                          order.activeTo !== null &&
                          addDays(new Date(payment.dueDate), 1) >
                          new Date(order?.activeTo ?? new Date())
                        )
                          return null;
                        return (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}
                            key={payment.id}
                            width="100%"
                            borderBottom="1px solid rgba(175, 175, 175, .4)">
                            {payment.isDue && (
                              <Tooltip
                                label="Betalningen misslyckades!"
                                float="left">
                                <WarningIcon
                                  color="red.400"
                                  mr={2}
                                />
                              </Tooltip>
                            )}
                            <Editable
                              style={{
                                color: '#797B80',
                                fontSize: '20px',
                                fontWeight: 500,
                                flexWrap: 'nowrap',
                              }}
                              defaultValue={payment.amount.toString()}
                              onSubmit={(val: string): void => {
                                updateNextPaymentAmount(val, payment.id);
                              }}>
                              <Box position="relative">
                                <EditablePreview />
                                <EditableInput />
                                <Text position="absolute" left="101%" top="3px">:-</Text>
                              </Box>
                            </Editable>
                            <Box>
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                }}>
                                {payment.taxAmount > 0 &&
                                  'Plus moms: ' + payment.taxAmount + ':-'}
                              </span>
                              <span
                                style={{
                                  color: '#797B80',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                }}
                              >
                                {new Date(payment.dueDate).toLocaleDateString()}
                              </span>
                            </Box>
                          </Box>
                        );
                      })}
                    </PaymentsDrawer>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                // NO CAPTURE
                <Spinner />
              )}
            </Flex>
          </Flex>
        </Flex>
        {order && order.unit && <Comments unitId={order.unit.id} orderId={order.id} />}
      </Container>
    </>
  );
}

function RenderCapture({ capture }: { capture: Payment }): JSX.Element {
  return (
    <Box
      key={capture.id + 'PARENT'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={4}
      width="100%"
      height="64px"
      borderBottom="1px solid rgba(175, 175, 175, .4)">
      <Box
        style={{
          color: '#797B80',
          fontSize: '20px',
          fontWeight: 500,
        }}>
        {capture.amount + (capture.taxAmount ?? 0)}:-
      </Box>
      <Box display="flex">
        <span
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 500,
          }}>
          {capture.taxAmount > 0 && 'Varav moms: ' + capture.taxAmount + ':-'}
        </span>

        <span
          style={{
            color: '#797B80',
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 500,
          }}>
          {capture.capturedAt &&
            <Box style={{ display: 'flex', alignItems: "center" }}>
              <Text ml={3}>
                {new Date(capture.capturedAt).toLocaleDateString()}
              </Text>
            </Box>}
        </span>
      </Box>
    </Box>
  );
}
function RenderCaptures({ captures }: { captures: Payment[] }): JSX.Element {
  return (
    <>
      {captures.map((capture) => {
        return (
          capture.capturedAt && (
            <RenderCapture key={capture.id} capture={capture} />
          )
        );
      })}
    </>
  );
}

function PaymentsDrawer({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}): JSX.Element {
  const [show, setShow] = useState(true);

  function handleToggle(): void {
    setShow(!show);
  }

  return (
    <Flex direction="column" fontFamily="Montserrat" mb={12}>
      <Box
        _hover={{ bg: '#F6F6F6', cursor: 'pointer' }}
        fontWeight="bold"
        flex="flex"
        p={3}
        width="100%"
        // height="64px"
        onClick={handleToggle}
        borderBottom="1px solid rgba(175, 175, 175, 0.4)">
        {title}
        <span style={{ float: 'right' }}>
          {!show ? (
            <Icon fill="#333" icon={ArrowDown} />
          ) : (
            <Icon fill="#333" icon={ArrowUp} />
          )}
        </span>
      </Box>
      <Collapse unmountOnExit={true} in={show}>
        <Box width="100%" overflow="auto" maxH="500px">
          {children}
        </Box>
      </Collapse>
    </Flex>
  );
}
