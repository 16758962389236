import React from 'react';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { DataTable } from 'components/DataTable/DataTable';
import { Campaign } from 'models/ApiModels';
import { useApi } from 'lib/useApi';
import { CampaignColumns } from 'components/DataTable/DataTableModels';

export const CampaignsPage = (): JSX.Element => {
    const { data: campaigns } = useApi<Campaign[]>(`/campaign`);

    let filteredCampaigns = []

    if (campaigns) {
        filteredCampaigns = campaigns.filter(campaign => campaign.isActive)
    }

    console.log(filteredCampaigns, 'filteredCampaigns')
    return (
        <>
            <ContainerHead children="" title="Kampanjer" />
            <Container>
                <Link to="kampanjer/skapa">
                    <Button variant="primary" mb={5}>
                        <AddIcon mr={3} />
                        Skapa kampanj
                    </Button>
                </Link>
                <DataTable
                    title="Kampanjer"
                    tableDef={CampaignColumns}
                    data={filteredCampaigns}
                    showSearch={true}
                    searchableFields={['name']}
                />
            </Container>
        </>
    );
};
