/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useRef, useEffect } from 'react';
import { TableDefinition } from './DataTableModels';
import TableHits from './TableHits';
import { TableSearchBox, TableTop } from './TableTop';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import useWindowInfo from '../../hooks/useWindowInfo'

export function DataTable({
  tableDef,
  data,
  searchableFields,
  showSearch = true,
  title,
  hitsPerPage = 0,
  showStats = true,
  searchPlaceholder
}: {
  tableDef: TableDefinition;
  data?: any[];
  searchableFields: string[];
  showSearch?: boolean;
  searchPlaceholder?: string
  title?: string;
  hitsPerPage?: number;
  showStats?: boolean;
}): JSX.Element {
  const [currentRefinement, setCurrentRefinement] = React.useState<string>('');
  const [hits, setHits] = React.useState<any[] | undefined>([]);
  const [items, setItems] = React.useState<any[]>([]);
  const [pageNum, setPageNum] = React.useState<number>(0)
  const bottomRef = useRef(null)

  const isBottomVisible = useIntersectionObserver(bottomRef, { root: null, threshold: 0.0000001 }, false)
  const [isMobile, isTouch] = useWindowInfo()

  useEffect(() => {
    isBottomVisible && setPageNum(pageNum + 1)
  }, [isBottomVisible])

  React.useMemo(() => {
    if (!data) return;

    const dataWithSearchable = data.map((x: any) => ({
      ...x,
      searchable: Object.keys(x).reduce((acc: string, currKey: string) => {
        if (
          searchableFields.includes(currKey) &&
          typeof x[currKey] === 'string'
        ) {
          acc += x[currKey]?.toLowerCase();
        }
        return acc;
      }, ''),
    }));

    setItems(dataWithSearchable);
    setHits(
      dataWithSearchable.slice(0, hitsPerPage > 0 ? hitsPerPage : items.length)
    );
  }, [data, hitsPerPage, items.length, searchableFields]);

  function refine(query: string): void {
    query = query.toLowerCase().trim();
    setCurrentRefinement(query);

    if (!query?.length) {
      setHits(items);
      return;
    }

    setHits(
      items
        ?.filter((x: any) => x.searchable.includes(query))
        .slice(0, hitsPerPage > 0 ? hitsPerPage : items.length)
    );
  }

  //hits.slice(0, hitsPerPage > 0 ? hitsPerPage : hits.length)
  return (
    <Box overflow="auto">
      <TableTop title={title}>
        <Flex mb={10}>
          {showSearch && (
            <TableSearchBox
              currentRefinement={currentRefinement}
              refine={refine}
              placeHolder={searchPlaceholder}
            />
          )}
          {showStats && hits.length > 1 && (
            <Text margin="auto 0" alignItems={"flex-start"}>
              Visar {hits?.length ?? 0} av {items.length}
            </Text>
          )}
        </Flex>
      </TableTop>
      {hits && <TableHits hits={hits} tableDef={tableDef} />}
      <Box ref={bottomRef} mt={20}></Box>
    </Box>
  );
}
