import { Container } from 'components/Container';
import { DataTable } from 'components/DataTable/DataTable';
import { notificationsTable } from 'components/DataTable/DataTableModels';
import { PortalContext } from 'PortalContext';
import React from 'react';
import { useApi } from '../lib/useApi';
import { Event } from 'models/ApiModels';
import { Heading } from '@chakra-ui/react';

export function NotificationsPage(): JSX.Element {
  const { data: eventsResp, state: respState } = useApi<Event[]>(
    '/events/notifications'
  );

  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <Container>
      <Heading pt={8}>Notifikationer</Heading>
      <DataTable
        tableDef={notificationsTable}
        data={eventsResp}
        searchableFields={['name']}
      />
    </Container>
  );
}
