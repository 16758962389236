import React, { Reducer } from 'react';
import { fetchApi } from './fetchApi';

interface UseApiInnerStateGeneric<T> {
  data?: T;
  state: requestStatusType;
  error?: string;
}

type requestStatusType = 'loading' | 'success' | 'error';

interface UseApiResponseGeneric<T = unknown>
  extends UseApiInnerStateGeneric<T> {
  reload: () => Promise<void>;
  setData: (data: T) => void;
}

export function useApi<T = unknown>(path: string): UseApiResponseGeneric<T> {
  const [innerState, dispatch] = React.useReducer<
    Reducer<UseApiInnerStateGeneric<T>, Action>
  >(reducer, { state: 'loading' });

  const setData = React.useCallback((data): void => {
    dispatch({ type: 'success', payload: { data } });
  }, []);

  const fetchData = React.useCallback(
    async function fetchData(): Promise<void> {
      if (!path) return;

      dispatch({ type: 'starting' });

      try {
        const res = await fetchApi(path);

        if (res.ok) {
          const json = await res.json();
          dispatch({ type: 'success', payload: { data: json } });
        } else {
          console.log(
            `Fetch result not ok: ${res.url} ${res.status} ${res.statusText}`
          );
          dispatch({ type: 'error', payload: { error: res.statusText } });
        }
      } catch (ex) {
        console.log('Fetch error:', ex);
        dispatch({ type: 'error', payload: { error: ex } });
      }
    },
    [path]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData, path]);

  return {
    reload: fetchData,
    setData,
    error: innerState.error,
    state: innerState.state,
    data: innerState.data,
  };
}

function reducer<T>(
  prevState: UseApiInnerStateGeneric<T>,
  action: Action
): UseApiInnerStateGeneric<T> {
  switch (action.type) {
    case 'starting':
      return {
        state: 'loading',
        data: prevState.data,
      } as UseApiInnerStateGeneric<T>;

    case 'success':
      return {
        state: 'success',
        data: action.payload?.data,
      } as UseApiInnerStateGeneric<T>;

    case 'error':
      return {
        state: 'error',
        error: action.payload?.error,
      } as UseApiInnerStateGeneric<T>;

    default:
      return prevState;
  }
}

interface Action {
  type: string;
  payload?: { [key: string]: unknown };
}
