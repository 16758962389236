export const getNumMonths = (date1: Date, date2: Date, roundUpFractionalMonths: boolean): number => {
    if (!date1 || !date2) {
        return 0
    }
    //Months will be calculated between start and end dates.
    //Make sure start date is less than end date.
    //But remember if the difference should be negative.
    let startDate = date1;
    let endDate = date2;
    let inverse = false;
    if (date1 > date2) {
        startDate = date2;
        endDate = date1;
        inverse = true;
    }

    //Calculate the differences between the start and end dates
    const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
    const monthsDifference = endDate.getMonth() - startDate.getMonth();
    const daysDifference = (endDate.getDate() - startDate.getDate());

    let monthCorrection = 0;
    //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
    //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
    if (roundUpFractionalMonths === true && daysDifference > 0) {
        monthCorrection = 1;
    }
    //If the day difference between the 2 months is negative, the last month is not a whole month.
    else if (roundUpFractionalMonths !== true && daysDifference < 0) {
        monthCorrection = -1;
    }

    return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection);
}
