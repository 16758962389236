import { Box, Button, Flex, Input, Select } from '@chakra-ui/react';
import { Product } from 'models/ApiModels';
import React, { useState, useRef } from 'react';

export type DirectLinkProps = {
  facility: number;
  products: Product[];
};

export default function CreateDirectLink({
  facility,
  products,
}: DirectLinkProps): JSX.Element {
  const [product, setProduct] = useState<string>();
  const webUrl = process.env.REACT_APP_WEB_URL;
  const urlRef = useRef<HTMLInputElement>(null);
  function getUrl(): string {
    if (product) {
      return `${webUrl}/checkout?f=${facility}&p=${product}`;
    }
    return '';
  }
  return (
    <Flex direction="row" pt={4}>
      <Box>
        <Select
          placeholder="Välj produkt"
          value={product}
          onChange={(e): void => setProduct(e.target.value)}>
          {products.map((p) => {
            return (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            );
          })}
        </Select>
      </Box>
      <Box pl={4}>
        <Input width={350} isReadOnly={true} ref={urlRef} value={getUrl()} />
      </Box>
      <Box pl={4}>
        <Button
          variant="primary"
          onClick={(): void => {
            urlRef.current?.select();
            document.execCommand('copy');
            // This is just personal preference.
            // I prefer to not show the whole text area selected.
          }}>
          Kopiera Länk
        </Button>
      </Box>
    </Flex>
  );
}
