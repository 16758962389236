import { Box, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';
import Statistics from 'style/statistics.module.css';
interface ContainerProps extends BoxProps {
  children: React.ReactNode;
  title: string;
}
export function DataContainer(props: ContainerProps): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={Statistics.invisibleCard}
      {...props}>
      <Box display="flex" flexDirection="column">
        <span className={Statistics.cardBigTitle}>{props.title}</span>
        <span className={Statistics.cardDesc}>{props.children}</span>
      </Box>
    </Box>
  );
}
