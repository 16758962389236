import React from 'react';
import firebase from 'firebase/app';
import { PortalRoot } from './PortalRoot';
import { LoginPage } from './pages/LoginPage';
import devBorderStyles from './style/devBorder.module.css';
import { Spinner } from '@chakra-ui/react';

function App(): JSX.Element {
  const [userStatus, setUserStatus] = React.useState('loading');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isSecuritas, setIsSecuritas] = React.useState(false);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (user) {
      setUserStatus(user ? 'logged_in' : 'logged_out');
    });
  }, []);
  React.useEffect(() => {
    if (userStatus === 'logged_in') {
      firebase
        .auth()
        .currentUser?.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (idTokenResult.claims?.roles.includes('portal:admin') || idTokenResult.claims?.roles.includes('portal:securitas')) {
            // Show admin UI.
            if(idTokenResult.claims?.roles.includes('portal:admin') ){
              setIsAdmin(true);
            }
            if(idTokenResult.claims?.roles.includes('portal:securitas')){
              setIsSecuritas(true);
            }
          } else {
            // Show regular user UI.
            setIsAdmin(false);
          }
        })
        .catch((error) => {
          setIsAdmin(false);
          console.log(error);
        });
    }
  }, [userStatus]);

  return (
    <AppWrap>
      {userStatus === 'loading' ? (
        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <Spinner />
        </div>
      ) : userStatus === 'logged_out' ? (
        <LoginPage />
      ) : userStatus === 'logged_in' &&  isAdmin || isSecuritas? (
        <PortalRoot />
      ) : (
        <p>Rättigheter saknas</p>
      )}
    </AppWrap>
  );
}

function AppWrap({ children }: { children: React.ReactNode }) {
  const isStaging = process.env.REACT_APP_ENV === 'staging';

  if (isStaging) {
    return <div className={devBorderStyles.devBorder}>{children}</div>;
  }

  return <>{children}</>;
}

export default App;
