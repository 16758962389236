import React from 'react';
import { ProductColumns } from 'components/DataTable/DataTableModels';
import { routes } from 'routes';
import { Container } from 'components/Container';
import { DataTable } from 'components/DataTable/DataTable';
import { useApi } from 'lib/useApi';
import { Product } from 'models/ApiModels';
import { ContainerHead } from 'components/ContainerHead';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { sortUnitsNum } from 'lib/sortUnits';

export function ProductsPage(): JSX.Element {
  const { data: products } = useApi<Product[]>(`/products`);
  let sortedProducts = []
  if (products) {
    sortedProducts = sortUnitsNum(products)
  }
  return (
    <>
      <ContainerHead title='Produkter' children={""} />
      <Container>
        <Link to={routes.createProduct.path}>
          <Button variant="primary" mb={10}><AddIcon mr={3} />Ny produkt</Button>
        </Link>
        <DataTable
          title="Produkter"
          tableDef={ProductColumns}
          data={sortedProducts}
          showSearch={true}
          searchableFields={['name', 'size']}
        />
      </Container>
    </>
  );
}
