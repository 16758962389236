import { Box, Text } from '@chakra-ui/react';
import { Container } from 'components/Container';
import { Form } from 'lib/form';
import React from 'react';
import { CreatePageProps } from 'routes';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from 'components/BackButton';

export function CreatePage({
  title,
  fields,
  postUrl,
  redirectUrl,
  successTitle,
}: CreatePageProps): JSX.Element {
  const params = useParams();
  const _fields = React.useMemo(() => fields(params), [fields, params]);
  const history = useHistory();

  return (
    <Container>
      <BackButton />
      <Text fontSize="4xl" mb={10} mt={5}>
        {title}
      </Text>
      <Box maxW={600}>
        <Form
          postUrl={postUrl}
          onSuccess={(e) => {
            if (redirectUrl) {
              history.push(redirectUrl.replace(':id', e.id));
            } else {
              history.goBack();
            }
          }}
          successTitle={successTitle}
          submitButtonText="Skapa">
          {_fields.map((fieldComponent) => {
            return React.cloneElement(fieldComponent, {
              key: fieldComponent.props.name,
            });
          })}
        </Form>
      </Box>
    </Container>
  );
}
