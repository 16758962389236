import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import Comments from 'components/Comments';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { DataContainer } from 'components/DataContainer';
import { DataTable } from 'components/DataTable/DataTable';
import { orderTable } from 'components/DataTable/DataTableModels';
import { Icon } from 'components/Icon';
import { SendUnitCheckoutLink } from 'components/SendUnitCheckoutLink';
import { StatusLabel } from 'components/StatusLabel';
import { fetchApi } from 'lib/fetchApi';
import { formatUnitNum } from 'lib/formatUnitNum';
import { Logout } from 'lib/IconLib';
import { useApi } from 'lib/useApi';
import { Order, Unit, } from 'models/ApiModels';
import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export default function UnitPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: unit } = useApi<Unit>(`/units/${id}`);
  const { data: orders } = useApi<Order[]>(`/orders/unit/${id}`);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef(null);
  const toast = useToast();

  async function UnlockUnit(): Promise<boolean> {
    const unlockedUnit = await fetchApi('/units/unlock?unitId=' + id);
    setIsAlertOpen(false);
    if (unlockedUnit.ok) {
      toast({
        title: 'Förråd upplåst',
        description: 'Nu kan förrådet bokas igen!',
        status: 'success',
        position: 'top',
        isClosable: true,
      });
      return true;
    }
    toast({
      title: 'Förråd kunde inte låsas upp',
      description: 'Något verkar ha gått fel!',
      status: 'error',
      position: 'top',
      isClosable: true,
    });
    return false;
  }

  function shouldShowUnlockButton(): boolean {
    const date = new Date(unit?.availableFrom ?? '').toLocaleDateString(
      'sv-SE'
    );
    const now = new Date().toLocaleDateString('sv-SE');
    return date > now;
  }

  return (
    <>
      <ContainerHead title="Förrådsinfo" backButton={true}>
        <DataContainer title="Förrådsnummer">
          {formatUnitNum(unit?.externalId)}
        </DataContainer>
        <DataContainer title="Anläggning">
          <Link
            style={{ display: 'flex' }}
            to={`/anlaggningar/${unit?.facility.id ?? ''}`}>
            {`${unit?.facility.name} `}
            <Icon icon={Logout} fill="#333" />
          </Link>
        </DataContainer>
        <DataContainer title="Status">
          {unit?.status && shouldShowUnlockButton() ? <StatusLabel status={0} /> : unit?.status ? <StatusLabel status={unit.status} /> : 'Ingen status'}
        </DataContainer>
        <DataContainer title="Storlek">
          {unit?.product?.size ?? 'Ingen storlek'} m<sup>2</sup>
        </DataContainer>
        <DataContainer title="Artikelnummer">{unit?.externalId}</DataContainer>
        {shouldShowUnlockButton() && (
          <DataContainer title="Låst till">
            {new Date(unit?.availableFrom ?? '').toLocaleDateString('sv-SE')}
          </DataContainer>
        )}
      </ContainerHead>
      <Container>
        <Link to={`${id}/edit`}>
          <Button
            variant="primary"
          >Ändra förrådsuppgifter</Button>
        </Link>

        {shouldShowUnlockButton() && (
          <Button ml={5} variant="primary" onClick={() => setIsAlertOpen(true)}>Lås upp förrådet i förtid</Button>
        )}

        <Box pt={8}>
          <DataTable
            title="Senaste ordrar"
            showSearch={false}
            tableDef={orderTable}
            data={orders}
            searchableFields={['id']}
          />
        </Box>

        {unit && unit.status !== 'BOOKED' && unit.status !== 'CHECKED_OUT' && (
          <Box py={8}>
            <SendUnitCheckoutLink unit={unit} />
          </Box>
        )}
        {unit && <Comments unitId={unit.id} />}
      </Container>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsAlertOpen(false);
        }}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Lås upp förråd i förtid
          </AlertDialogHeader>

          <AlertDialogBody>
            Är du säker på att du vill låsa upp det här förrådet? Det kommer att
            öppnas för bokning direkt.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              variant="secondary"
              ref={cancelRef}
              onClick={() => {
                setIsAlertOpen(false);
              }}>
              Gå tillbaka
            </Button>
            <Button variant="primary" onClick={UnlockUnit} ml={3}>
              Lås upp!
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
