import { Box, Button } from '@chakra-ui/react';
import { usersTable } from 'components/DataTable/DataTableModels';
import { useApi } from 'lib/useApi';
import React from 'react';
import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons'
import { colors } from 'lib/constants';
import { NumberCard } from 'components/NumberCard';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { User } from 'models/ApiModels';
import { DataTable } from 'components/DataTable/DataTable';

export function UsersPage(): JSX.Element {
  const statsResp = useApi<{ [key: string]: number }>('/statistics/users');
  const { data: users } = useApi<User[]>(`/users`);

  return (
    <>
      <ContainerHead title="Konton">
        <NumberCard title="Totalt antal konton" number={users?.length ?? 0} />
        <NumberCard
          title="Totalt antal avtal"
          number={statsResp.data?.totalRentedUnits ?? 0}
        />
      </ContainerHead>
      <Container>
        <Link to="/konton/skapa">
        <Button
          variant="primary"
          mb={10}
        ><AddIcon w={3} h={3} mr={3}/> Lägg till konto</Button>
        </Link>
        <hr style={{marginBottom: 35, maxWidth: 1000}} />
        <Box>
          <DataTable
            tableDef={usersTable}
            data={users}
            showSearch={true}
            searchPlaceholder="Sök efter kund"
            searchableFields={['email']}
          />
        </Box>
      </Container>
    </>
  );
}
