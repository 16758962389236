import {
    Box,
    FormLabel,
    Input,
    SimpleGrid,
    FormControl,
    Select,
    Checkbox,
    Textarea,
    Text,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Tag,
    Button,
    ButtonGroup,
    useToast,
} from '@chakra-ui/react';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import DatePicker from 'components/DatePicker';
import React, { useState, useRef, useEffect } from 'react';
import { useApi } from '../../lib/useApi';
import { postApi, patchApi } from 'lib/fetchApi';
import { Facility, Discount, Product } from 'models/ApiModels';
import { colors, elevation } from 'lib/constants';
import { AddIcon, MinusIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { campaignStyles } from 'style/campaign';
import CampaignDiscount from 'components/Campaign/CampaignDiscount';
import { getNumMonths } from 'lib/dateHelpers';
import { Campaign } from 'models/ApiModels';
import { convertIdToStringArray } from 'lib/convertIdToStringArray';
import { useHistory } from 'react-router-dom';

const today = new Date();
const oneDayInMS = 86400000;
const yesterDay = new Date(today.getTime() - oneDayInMS);
const tomorrow = new Date(today.getTime() + oneDayInMS);

const allFacilitiesString = 'Alla anläggningar';

interface CampaignPageProps {
    mode: 'add' | 'edit',
    campaign?: Campaign
}

interface Data {
    Id?: number,
    Name: string,
    IsActive: boolean,
    Description: string,
    ActiveFrom: string,
    ActiveTo?: string,
    Budget: null,
    Discounts: Discount[],
    AppliesToFacilityId: number[],
    AppliesToAll: boolean
}

const emptyDiscount: Discount = {
    name: '',
    discountType: 'fixed',
    isActive: true,
    requireFirstOrder: true,
    requireUnique: true,
    discountedMonths: 0,
    appliesToProductId: [],
    appliesToAll: false,
    percentOff: 0,
    amountOff: 0,
    includeFirstMonth: false
}

export const AddCampaignsPage = ({ campaign, mode }: CampaignPageProps): JSX.Element => {
    console.log(campaign, 'campaign')
    const { data: facilitiesResp } = useApi<Facility[]>('/facilities');
    const { data: products } = useApi<Product[]>(`/products`);

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(mode === 'edit' ? new Date(campaign.activeFrom) : new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(mode === 'edit' ? campaign.activeTo ? new Date(campaign.activeTo) : null : tomorrow);
    const [endless, setEndless] = useState<boolean>(mode === 'edit' && campaign.activeTo === null);
    const [selectedFacilities, setSelectedFacilities] = useState<string[]>(mode === 'edit' ? campaign.appliesToFacilityId.map(x => x.toString()) : []);
    const [discountCounter, setDiscountCounter] = useState<number>(mode === 'edit' ? campaign.discounts.length : 1);

    const [discounts, setDiscounts] = useState<Discount[]>(mode === 'edit' ? campaign.discounts : [emptyDiscount])

    const [tabIndex, setTabIndex] = useState<number>(0)

    const nameRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLTextAreaElement>(null)

    const history = useHistory();
    const toast = useToast();

    const setSelectedStartDateHandler = (date: Date): void => {
        if (date !== undefined) {
            setSelectedStartDate(date);
            if (date > selectedEndDate) {
                setSelectedEndDate(new Date(date.getTime()));
            }
        }
    };

    const setSelectedEndDateHandler = (date: Date): void => {
        if (date !== undefined) {
            setSelectedEndDate(date);
        }
    };

    const facilityClickHandler = (facility: any) => {
        if (facility.length > 0) {
            if (facility === allFacilitiesString) {
                if (selectedFacilities.includes(allFacilitiesString)) {
                    setSelectedFacilities([]);
                } else {
                    setSelectedFacilities([allFacilitiesString]);
                }
            } else {
                if (!selectedFacilities.includes(facility)) {
                    setSelectedFacilities([...selectedFacilities, facility]);
                } else {
                    setSelectedFacilities(
                        selectedFacilities.filter((x) => x !== facility)
                    );
                }
            }
        }
    };

    const AddCampaign = async (): Promise<boolean> => {
        let facilityIds = []
        facilityIds = facilitiesResp.filter(facility => selectedFacilities.includes(allFacilitiesString) ? facility : selectedFacilities.includes(facility.name)).map(x => x.id)

        let data: Data = {
            Name: nameRef.current.value,
            Description: descriptionRef.current.value,
            ActiveFrom: selectedStartDate.toJSON(),
            ActiveTo: endless ? null : selectedEndDate.toJSON(),
            Budget: null,
            IsActive: true,
            Discounts: discounts,
            AppliesToFacilityId: facilityIds,
            AppliesToAll: selectedFacilities.includes(allFacilitiesString),
        }

        if (mode === 'edit') {
            data = { ...data, Id: campaign.id }
        }

        console.log(data, 'data')

        let campaignRes

        if (mode === 'edit') {
            campaignRes = await postApi('/Campaign/update', data)
        } else {
            campaignRes = await postApi('/Campaign/add',
                data
            )
        }

        if (campaignRes.ok) {
            toast({
                title: `Kampanj ${mode === 'edit' ? 'ändrad' : 'skapad'}`,
                description: "Åtgärden lyckades",
                status: 'success',
                position: 'top',
                isClosable: true,
            });
            history.goBack()
            return true
        }
        toast({
            title: `Något gick fel`,
            description: "Åtgärden misslyckades",
            status: 'error',
            position: 'top',
            isClosable: true,
        });
        history.goBack()
        return false
    };

    const handleRemoveCampaign = async (Id: number): Promise<boolean> => {
        // let facilityIds = []
        // facilityIds = facilitiesResp.filter(facility => selectedFacilities.includes(allFacilitiesString) ? facility : selectedFacilities.includes(facility.name)).map(x => x.id)

        // const data: Data = {
        //     Name: nameRef.current.value,
        //     Description: descriptionRef.current.value,
        //     ActiveFrom: selectedStartDate.toJSON(),
        //     ActiveTo: selectedEndDate ? selectedEndDate.toJSON() : null,
        //     IsActive: false,
        //     Budget: null,
        //     Discounts: discounts,
        //     AppliesToFacilityId: facilityIds,
        //     AppliesToAll: selectedFacilities.includes(allFacilitiesString),
        //     Id
        // }

        const res = await patchApi(`/Campaign/disable/${Id}`, {})

        if (res.ok) {
            toast({
                title: 'Kampanj borttagen',
                description: "Åtgärden lyckades",
                status: 'success',
                position: 'top',
                isClosable: true,
            });
            history.goBack()
            return true
        }
        toast({
            title: `Något gick fel`,
            description: "Åtgärden misslyckades",
            status: 'error',
            position: 'top',
            isClosable: true,
        });
        return false
    }

    const handleTabChange = (index: number): void => {
        setTabIndex(index)
    }

    const handleAddTab = (): void => {
        setDiscounts([...discounts, emptyDiscount])
        setDiscountCounter(discountCounter + 1)
    }

    const handleRemoveTab = (): void => {
        const newDiscounts = discounts.slice(0, -1)
        if (discountCounter == tabIndex + 1) {
            setDiscounts(newDiscounts)
            setTabIndex(tabIndex - 1)
            setDiscountCounter(discountCounter - 1)
        } else {
            setDiscounts(newDiscounts)
            setDiscountCounter(discountCounter - 1)
        }
    }

    let sortedFacilities = [];
    if (facilitiesResp) {
        sortedFacilities = facilitiesResp.sort((a: any, b: any): number =>
            a.name.localeCompare(b.name)
        );
    }

    useEffect(() => {
        if (mode === 'edit' && campaign && facilitiesResp) {
            setSelectedFacilities(convertIdToStringArray(campaign.appliesToFacilityId, campaign.appliesToAll, allFacilitiesString, facilitiesResp))
        }
    }, [facilitiesResp])

    return (
        <>
            <ContainerHead children="" title={mode === 'edit' ? "Ändra kampanj" : "Skapa kampanj"} />
            <Container>
                <Box style={{ fontSize: 14, fontWeight: 600, maxWidth: '1000px' }}>
                    <Box shadow={elevation['+40']} p={10} borderRadius="1em" mb={10}>
                        <Text mr={5} mb={5} fontSize="2xl">Kampanjval</Text>
                        <SimpleGrid columns={2} spacing={10}>
                            <FormControl isRequired>
                                <FormLabel style={campaignStyles.label} htmlFor="name">
                                    Namn
                                </FormLabel>
                                <Input id="name" placeholder="Namn på kampanjen" ref={nameRef} defaultValue={mode === 'edit' ? campaign.name : ''} />
                            </FormControl>
                            <Box></Box>
                            <FormControl isRequired>
                                <FormLabel style={campaignStyles.label} htmlFor="facility">
                                    Anläggning
                                </FormLabel>
                                <Select
                                    id="facility"
                                    placeholder="Välj anläggning"
                                    onChange={(e) => facilityClickHandler(e.target.value)}>
                                    <option
                                        style={{
                                            color: selectedFacilities.includes(allFacilitiesString)
                                                ? colors.primary[900]
                                                : 'initial',
                                        }}>
                                        Alla anläggningar
                                    </option>
                                    {sortedFacilities.length > 0 &&
                                        !selectedFacilities.includes(allFacilitiesString) &&
                                        sortedFacilities.map((facility, i) => (
                                            <option
                                                className="selected"
                                                style={{
                                                    color: selectedFacilities.includes(facility.name)
                                                        ? colors.primary[900]
                                                        : 'initial',
                                                }}
                                                key={i}
                                                value={facility.name}>
                                                {facility.name}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                            <Box mb={10}>
                                {selectedFacilities.length > 0 && (
                                    <>
                                        <Text mb={3} style={campaignStyles.label}>
                                            Valda Anläggningar
                                        </Text>
                                        <Box display="flex" flexWrap="wrap">
                                            {selectedFacilities.map((facility, i) => (
                                                <Box _hover={{ cursor: 'pointer' }} key={i}>
                                                    <Tag
                                                        size="lg"
                                                        mr={3}
                                                        mb={3}
                                                        pr={6}
                                                        style={{
                                                            position: 'relative',
                                                            backgroundColor: colors.primary[900],
                                                            color: colors.white,
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setSelectedFacilities(
                                                                selectedFacilities.filter((x) => x !== facility)
                                                            );
                                                        }}>
                                                        <SmallCloseIcon
                                                            style={{ position: 'absolute', top: 3, right: 3 }}
                                                        />
                                                        {facility}
                                                    </Tag>
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Checkbox
                                checked={endless}
                                defaultChecked={endless}
                                onChange={() => setEndless(!endless)}
                                size="lg"
                                colorScheme="orange"
                                style={{
                                    display: 'flex',
                                    marginBottom: 10,
                                    marginRight: 10
                                }}>
                                <Text style={campaignStyles.label}>Tillsvidarekampanj</Text>
                            </Checkbox>
                            <Box />
                            <SimpleGrid columns={{ base: 1, sm: 2, xl: 2 }} spacing={10} mb={10}>
                                <FormControl isRequired>
                                    <FormLabel style={campaignStyles.label} htmlFor="startDate">
                                        Kampanjstart
                                    </FormLabel>
                                    <DatePicker
                                        minW={false}
                                        dayPickerProps={{
                                            mode: 'single',
                                            selected: selectedStartDate,
                                            onSelect: setSelectedStartDateHandler,
                                            disabled: [{ from: new Date(0), to: yesterDay }],
                                        }}>
                                        {selectedStartDate?.toLocaleDateString()}
                                    </DatePicker>
                                </FormControl>
                                <FormControl isRequired={!endless}>
                                    <FormLabel
                                        htmlFor="endDate"
                                        style={{
                                            ...campaignStyles.label,
                                            visibility: endless ? 'hidden' : 'visible',
                                        }}>
                                        Kampanjslut
                                    </FormLabel>
                                    <DatePicker
                                        disabled={endless}
                                        minW={false}
                                        dayPickerProps={{
                                            mode: 'single',
                                            selected: selectedEndDate,
                                            onSelect: setSelectedEndDateHandler,
                                            disabled: [
                                                { from: new Date(0), to: new Date(selectedStartDate) },
                                            ],
                                        }}>
                                        {selectedEndDate?.toLocaleDateString()}
                                    </DatePicker>
                                </FormControl>

                            </SimpleGrid>
                        </SimpleGrid>
                        <Box mt={5}>
                            <FormControl isRequired>
                                <FormLabel style={campaignStyles.label} htmlFor="description" >
                                    Kampanjbeskrivning
                                </FormLabel>
                                <Textarea size="lg" id="description" ref={descriptionRef} />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box shadow={elevation['+40']} p={10} borderRadius="1em" mb={10}>
                        <Box style={{ display: 'flex' }}>
                            <Text mr={5} mb={5} fontSize="2xl">{discountCounter > 1 ? 'Rabatter' : 'Rabatt'}</Text>
                        </Box>
                        <Tabs colorScheme="orange" variant="line" index={tabIndex} onChange={handleTabChange}>
                            {discountCounter > 1 && <TabList>
                                {[...Array(discountCounter)].map((_, i) => (
                                    <Tab key={i}>Rabatt {i + 1}</Tab>
                                ))}
                            </TabList>}
                            <TabPanels>
                                {[...Array(discountCounter)].map((_, i) => (
                                    <TabPanel key={i}>
                                        <CampaignDiscount mode={mode} products={products} index={i} setDiscounts={setDiscounts} discounts={discounts} numMonths={getNumMonths(selectedStartDate, selectedEndDate, true)} />
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                        {mode !== 'edit' && <ButtonGroup variant="primary" spacing={5}>
                            <Button onClick={handleAddTab}>
                                <AddIcon mr={3} /> Lägg till rabatt
                            </Button>
                            <Button
                                disabled={discountCounter < 2}
                                onClick={handleRemoveTab}>
                                <MinusIcon mr={3} /> Ta bort rabatt
                            </Button>
                        </ButtonGroup>}
                    </Box>
                </Box>
                {mode === 'edit' ? <Box style={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '1000px',
                }}>
                    {/* <Button mt={10} mb={100} px={10} variant="primary" onClick={AddCampaign}>Ändra kampanj</Button> */}
                    <Button mt={10} mb={100} ml={5} px={10} variant="primary" onClick={() => handleRemoveCampaign(campaign.id)}>Inaktivera kampanj</Button>
                </Box> : <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '1000px',
                    }}>
                    <Button mt={10} mb={100} px={100} variant="primary" onClick={AddCampaign}>
                        Skapa Kampanj
                    </Button>
                </Box>}
            </Container>
        </>
    );
};
