import React from 'react'
import { Box, Tag } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { colors } from 'lib/constants';

interface DiscountUnitTagProps {
    facility: string
    onClickHandler: any
}

const DiscountUnitTag = ({ facility, onClickHandler }: DiscountUnitTagProps): JSX.Element => {
    return (
        <Box _hover={{ cursor: 'pointer' }}>
            <Tag
                size="lg"
                mr={3}
                mb={3}
                pr={6}
                style={{
                    position: 'relative',
                    backgroundColor: colors.primary[900],
                    color: colors.white,
                }}
                onClick={(e) => {
                    onClickHandler(e)
                }}>
                <SmallCloseIcon
                    style={{ position: 'absolute', top: 3, right: 3 }}
                />
                {facility}
            </Tag>
        </Box>
    )
}

export default DiscountUnitTag