import {
    Box,
    Text,
    SimpleGrid,
    FormControl,
    FormLabel,
    Select,
    Input,
    Checkbox,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { campaignStyles } from 'style/campaign';
import { Discount, Product } from 'models/ApiModels';
import { colors } from 'lib/constants';
import { sortUnitsNum } from 'lib/sortUnits';
import { convertIdToStringArray } from 'lib/convertIdToStringArray'
import DiscountUnitTag from './DiscountUnitTag';

interface CampaignDiscountProps {
    index: number;
    products: Product[];
    numMonths: number;
    setDiscounts: React.Dispatch<React.SetStateAction<Discount[]>>;
    discounts: Discount[];
    mode: 'edit' | 'add'
}

const allProductsString = 'Alla förrådstyper';

const CampaignDiscount = ({
    index,
    products,
    setDiscounts,
    discounts,
    numMonths,
    mode
}: CampaignDiscountProps): JSX.Element => {

    const [conditionType, setConditionType] = useState<'fixed' | 'percent'>(discounts[index]?.discountType);
    const [discountLengthType, setDiscountLengthType] = useState<'firstMonth' | 'xMonth'>(
        discounts[index]?.discountedMonths === 1 ? 'firstMonth' : 'xMonth'
    );
    const [agreementLength, setAgreementLength] = useState<number>(discounts[index]?.discountedMonths);
    const [discountLength, setDiscountLength] = useState<string>('0');
    const [discountPeriod, setDiscountPeriod] = useState<string>('start');
    // const [selectedProducts, setSelectedProducts] = useState<string[]>([])
    const [selectedProducts, setSelectedProducts] = useState<string[]>(discounts[index].appliesToProductId.map(x => x.toString()));
    const [discountValue, setDiscountValue] = useState<number>(discounts[index]?.discountType === 'fixed' ? discounts[index]?.amountOff : discounts[index]?.percentOff);
    const [requireUnique, setRequireUnique] = useState<boolean>(discounts[index]?.requireUnique)
    const [requireFirstOrder, setRequireFirstOrder] = useState<boolean>(discounts[index]?.requireFirstOrder)
    const [appliesToAll, setAppliesToAll] = useState<boolean>(discounts[index]?.appliesToAll)
    const [includeFirstMonth, setIncludeFirstMonth] = useState<boolean>(discounts[index]?.includeFirstMonth)

    useEffect(() => {
        if (!products) {
            return
        }

        let productIds = [];
        if (appliesToAll) {
            productIds = products?.map((x) => x.id);
        } else if (products) {
            productIds = products
                .filter((product) => selectedProducts.includes(product.name))
                .map((x) => x.id);
        }

        const data: any = {
            name: 'namn',
            discountType: conditionType,
            isActive: mode === 'edit' ? false : true,
            requireFirstOrder,
            requireUnique,
            discountedMonths: discountLengthType === 'firstMonth' ? 1 : agreementLength,
            appliesToProductId: productIds,
            percentOff: conditionType === 'percent' ? discountValue : null,
            amountOff: conditionType === 'fixed' ? discountValue : null,
            appliesToAll: appliesToAll,
            includeFirstMonth: includeFirstMonth
        };

        const newDiscounts = discounts;
        if (mode === 'edit') {
            data.id = discounts[index].id
        }
        newDiscounts[index] = data;
        console.log(newDiscounts, 'newDiscounts')
        setDiscounts(newDiscounts);
    }, [
        conditionType,
        discountLengthType,
        agreementLength,
        discountLength,
        discountPeriod,
        selectedProducts,
        discountValue,
        numMonths,
        includeFirstMonth
    ]);

    const productClickHandler = (product) => {
        if (product.length > 0) {
            if (product === allProductsString) {
                if (appliesToAll) {
                    setAppliesToAll(false)
                    setSelectedProducts([]);
                } else {
                    setAppliesToAll(true)
                    setSelectedProducts(products.map(product => product.name));
                }
            } else {
                if (!selectedProducts.includes(product)) {
                    setSelectedProducts([...selectedProducts, product]);
                } else {
                    setSelectedProducts(selectedProducts.filter((x) => x !== product));
                }
            }
        }
    };

    useEffect(() => {
        if (products && discounts && discounts[index] && !appliesToAll) {
            setSelectedProducts(convertIdToStringArray(discounts[index].appliesToProductId, discounts[index].appliesToAll, allProductsString, products))
        }
    }, [products])

    let sortedProducts = [];
    if (products) {
        sortedProducts = sortUnitsNum(products);
    }

    const tagClickHandler = (e: any, facility: any): void => {
        e.preventDefault();
        if (appliesToAll) {
            setAppliesToAll(false)
            setSelectedProducts([])
        } else {
            setSelectedProducts(
                selectedProducts.filter((x) => x !== facility))
        }

    }

    return (
        <Box pt={5}>
            <SimpleGrid columns={2} spacing={10} mb={10}>
                <FormControl isRequired>
                    <FormLabel style={campaignStyles.label} htmlFor="facility">
                        Förrådstyp
                    </FormLabel>
                    <Select
                        id="facility"
                        placeholder="Välj förrådstyp"
                        onChange={(e) => productClickHandler(e.target.value)}>
                        <option
                            style={{
                                color: appliesToAll
                                    ? colors.primary[900]
                                    : 'initial',
                            }}>
                            {allProductsString}
                        </option>
                        {products &&
                            sortedProducts.length > 0 &&
                            !appliesToAll &&
                            sortedProducts.map((p, i) => (
                                <option
                                    className="selected"
                                    style={{
                                        color: selectedProducts?.includes(p.name)
                                            ? colors.primary[900]
                                            : 'initial',
                                    }}
                                    key={i}
                                    value={p.name}>
                                    {p.name}
                                </option>
                            ))}
                    </Select>
                </FormControl>
                <Box mb={10}>
                    {(selectedProducts.length > 0 || appliesToAll) && (
                        <>
                            <Text mb={3} style={campaignStyles.label}>
                                Valda Förrådstyper
                            </Text>
                            <Box display="flex" flexWrap="wrap">
                                {appliesToAll ? <DiscountUnitTag facility={allProductsString} onClickHandler={tagClickHandler} /> : (selectedProducts.map((facility, i) => (
                                    <DiscountUnitTag facility={facility} onClickHandler={tagClickHandler} key={i} />)
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} mb={5}>
                {/* <Checkbox
                    size="lg"
                    colorScheme="orange"
                    defaultChecked={!requireUnique}
                    checked={!requireUnique}
                    onChange={() => setRequireUnique(!requireUnique)}
                    style={styles.checkbox}>
                    <Text style={campaignStyles.label}>Kan ej kombineras med andra erbjudanden</Text>
                </Checkbox> */}
                <Checkbox
                    size="lg"
                    colorScheme="orange"
                    defaultChecked={includeFirstMonth}
                    checked={includeFirstMonth}
                    onChange={() => setIncludeFirstMonth(!includeFirstMonth)}
                    style={styles.checkbox}>
                    <Text style={campaignStyles.label}>Inkludera påbörjad månad</Text>
                </Checkbox>
                <Checkbox
                    size="lg"
                    colorScheme="orange"
                    defaultChecked={requireFirstOrder}
                    checked={requireFirstOrder}
                    onChange={() => setRequireFirstOrder(!requireFirstOrder)}
                    style={styles.checkbox}>
                    <Text style={campaignStyles.label}>Gäller endast nya kunder</Text>
                </Checkbox>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, sm: 2, xl: 3 }} spacing={5} mb={10}>
                <FormControl isRequired>
                    <FormLabel style={campaignStyles.label} htmlFor="conditionType">
                        Typ av rabatt
                    </FormLabel>
                    <Select
                        id="discountType"
                        value={conditionType}
                        onChange={(e) => setConditionType(e.target.value === 'fixed' ? 'fixed' : 'percent')}>
                        <option value="fixed">Fast rabatt i kronor</option>
                        <option value="percent">Procentuell rabatt</option>
                    </Select>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel style={campaignStyles.label} htmlFor="discountValue">
                        {conditionType.length > 0 && conditionType === 'fixed'
                            ? 'Ange rabatt i kr'
                            : 'Ange rabatt i %'}
                    </FormLabel>
                    <Input
                        type="number"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(parseInt(e.target.value))}
                    />
                </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, sm: 2, xl: 3 }} spacing={5} mb={10}>
                <FormControl isRequired>
                    <FormLabel style={campaignStyles.label} htmlFor="conditionType">
                        Rabatten gäller
                    </FormLabel>
                    <Select
                        id="conditionType"
                        onChange={(e) => { setDiscountLengthType(e.target.value === 'firstMonth' ? 'firstMonth' : 'xMonth') }}
                        value={discountLengthType}>
                        <option value="firstMonth">första månaden</option>
                        <option value="xMonth">X månader</option>
                    </Select>
                </FormControl>
                {discountLengthType === 'xMonth' && (
                    <FormControl isRequired>
                        <FormLabel
                            style={campaignStyles.label}
                            htmlFor="conditionTypeValue">
                            {`Ange X antal månader`}
                        </FormLabel>
                        <Input
                            id="condtionTypeValue"
                            value={agreementLength}
                            onChange={(e) => setAgreementLength(parseInt(e.target.value))}
                            type="number"
                        />
                    </FormControl>)}
            </SimpleGrid>
            {/* {discountLengthType === 'part' && (
                <SimpleGrid columns={{ base: 1, sm: 2, xl: 3 }} spacing={5} mb={10}>
                    <FormControl isRequired>
                        <FormLabel
                            style={campaignStyles.label}
                            htmlFor="conditionTypeValue">
                            {`Antal rabatterade månader`}
                        </FormLabel>
                        <Input
                            id="condtionTypeValue"
                            value={discountLength}
                            onChange={(e) => setDiscountLength(e.target.value)}
                            type="number"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel style={campaignStyles.label} htmlFor="discountPeriod">
                            Rabatten erhålls
                        </FormLabel>
                        <Select
                            id="discountPeriod"
                            value={discountPeriod}
                            onChange={(e) => setDiscountPeriod(e.target.value)}>
                            <option value="start">I början av avtalsperioden</option>
                            <option value="end">I slutet av avtalsperioden</option>
                            <option value="entirePeriod">Hela avtalsperioden</option>
                        </Select>
                    </FormControl>
                </SimpleGrid>
            )} */}
        </Box >
    );
};

export default CampaignDiscount;

const styles = {
    checkbox: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 10,
        marginRight: 10,
    }
}