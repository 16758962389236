import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { DataTable } from 'components/DataTable/DataTable';
import { transfersTable } from 'components/DataTable/DataTableModels';
import { fetchApi, postApi } from 'lib/fetchApi';
import { useApi } from 'lib/useApi';
import { Transfers } from 'models/ApiModels';
import React, { useRef, useState } from 'react';

export function SuperAdminPage(): JSX.Element {
  const { data: transfers } = useApi<Transfers>('/orders/transfers');
  const cancelRef = useRef(null);
  const [facility, setFacility] = useState<string>();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  function handleclick() {
    fetchApi(`/orders/transfers/${facility}`);
    setIsAlertOpen(false);
  }

  const facilities = [
    // '40001',
    // '40002',
    // '40003',
    // '40004',
    // '40005',
    // '40006',
    // '40007',
    // '40008',
    // '40009',
    // '40010',
    // '40011',
    // '40101',
  ];

  return (
    <>
    <ContainerHead children="" title='Superadmin' />
    <Container>
      <Box>
        <Flex direction="column">
          {facilities.map((id: string) => {
            return (
              <Button
                mb={2}
                key={id}>
                Skicka {id}
              </Button>
            );
          })}
        </Flex>
        <DataTable
          tableDef={transfersTable}
          data={transfers}
          hitsPerPage={100}
          searchableFields={['externalId', 'orderEmail']}
        />
      </Box>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsAlertOpen(false);
        }}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            SEND EMAIL
          </AlertDialogHeader>

          <AlertDialogBody>Send {facility} ID EMAILS</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                setIsAlertOpen(false);
              }}>
              Gå tillbaka
            </Button>
            <Button colorScheme="red" ml={3}>
              Skicka
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container >
    </>
  );
}
