import { ReactComponent as rootIcon } from 'static/icons/More-Vert.svg';
import { ReactComponent as usersIcon } from 'static/icons/Person.svg';
import { ReactComponent as productsIcon } from 'static/icons/Lock.svg';
import { ReactComponent as facilitiesIcon } from 'static/icons/Facilities.svg';
import { ReactComponent as placesIcon } from 'static/icons/Pin.svg';
import { ReactComponent as arrowLeft } from 'static/icons/Dropdown/Left.svg';
import { ReactComponent as arrowUp } from 'static/icons/Dropdown/Up.svg';
import { ReactComponent as arrowDown } from 'static/icons/Dropdown/Down.svg';
import { ReactComponent as logout } from 'static/icons/Logout.svg';
import { ReactComponent as ordersIcon } from 'static/icons/Order.svg';
import { ReactComponent as personIcon } from 'static/icons/Person.svg';
import { ReactComponent as moreIcon } from 'static/icons/More-Vert.svg';
import { ReactComponent as lateIcon } from 'static/icons/Late.svg';
import { ReactComponent as keyIcon } from 'static/icons/Key.svg';
import { ReactComponent as campaignIcon } from 'static/icons/Campaign.svg';

export const RootIcon = rootIcon;
export const UsersIcon = usersIcon;
export const ProductsIcon = productsIcon;
export const FacilitiesIcon = facilitiesIcon;
export const PlacesIcon = placesIcon;
export const ArrowLeft = arrowLeft;
export const Logout = logout;
export const ArrowUp = arrowUp;
export const ArrowDown = arrowDown;
export const OrdersIcon = ordersIcon;
export const PersonIcon = personIcon;
export const MoreIcon = moreIcon;
export const LateIcon = lateIcon;
export const KeyIcon = keyIcon;
export const CampaignIcon = campaignIcon;