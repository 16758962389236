import { Heading, Box, Spinner, Text } from '@chakra-ui/react';
import { Container } from 'components/Container';
import { fetchApi } from 'lib/fetchApi';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { useDebouncedCallback } from 'use-debounce';

export function SearchPage(): JSX.Element {
  const queryHelper = useQuery();
  const [state, setState] = React.useState('loading');
  const [results, setResults] = React.useState<{
    query: string;
    items: { [key: string]: SearchResult[] };
  }>();
  const query = React.useMemo(() => queryHelper.get('q'), [queryHelper]);

  async function fetchResults(query: string): Promise<void> {
    if (query) {
      const resp = await fetchApi('/search?query=' + query);
      setResults({
        query,
        items: resp.ok ? await resp.json() : {},
      });
    } else {
      setResults({ query, items: {} });
    }

    setState('idle');
  }

  const debounced = useDebouncedCallback((value: string) => {
    fetchResults(value);
  }, 400);

  React.useEffect(() => {
    setState('loading');
    debounced.callback(query || '');
  }, [debounced, query]);

  return (
    <Container>
      {state === 'loading' && <Spinner />}
      {state !== 'loading' && (
        <>
          {results?.query && (
            <>
              <Text fontSize="2xl" mb={4} color="#E9500E">
                Sökresultat för <Text as="i">"{results?.query}"</Text>
              </Text>
              <Text fontSize="xs">
                (OBS! visar max 7 resultat per kategori)
              </Text>
            </>
          )}
          {results?.items &&
            Object.keys(results.items).map((x) => (
              <SearchReultsCategory
                key={x}
                typename={x}
                items={results.items[x]}
              />
            ))}
        </>
      )}
    </Container>
  );
}

function SearchReultsCategory({
  typename,
  items,
}: {
  typename: string;
  items: SearchResult[];
}): JSX.Element | null {
  if (!items.length) {
    return null;
  }

  return (
    <>
      <Heading as="h2" fontSize="xl" mb={3} mt={8}>
        {searchResultTypes[typename].name}
      </Heading>
      {items?.map((x) => (
        <SearchResultItem
          key={x.id}
          item={x}
          type={searchResultTypes[typename]}
        />
      ))}
    </>
  );
}

function SearchResultItem({
  item,
  type,
}: {
  item: SearchResult;
  type: SearchResultType;
}): JSX.Element {
  return (
    <Link to={type.linkBuilder(item.id)}>
      <Box
        _hover={{ background: '#fafafa' }}
        paddingY={3}
        borderBottomWidth="1px">
        <Text fontSize="l">{item.title}</Text>
        {item.description && (
          <Text color="#666" mt={2}>
            {item.description}
          </Text>
        )}
      </Box>
    </Link>
  );
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

interface SearchResult {
  id: string;
  title: string;
  entityType: string;
  description?: string;
}

const searchResultTypes: {
  [key: string]: SearchResultType;
} = {
  facilities: {
    name: 'Anläggningar',
    linkBuilder: (id) => {
      return routes.facility.path.replace(':id', id.toString());
    },
  },
  orders: {
    name: 'Ordrar',
    linkBuilder: (id) => {
      return routes.order.path.replace(':id', id.toString());
    },
  },
  units: {
    name: 'Objekt',
    linkBuilder: (id) => {
      return routes.unit.path.replace(':id', id.toString());
    },
  },
  places: {
    name: 'Platser',
    linkBuilder: (id) => {
      return routes.place.path.replace(':id', id.toString());
    },
  },
  products: {
    name: 'Produkter',
    linkBuilder: (id) => {
      return routes.product.path.replace(':id', id.toString());
    },
  },
  payments: {
    name: 'Betalningar',
    linkBuilder: (id) => {
      return routes.payment.path.replace(':id', id.toString());
    },
  },
  users: {
    name: 'Användare',
    linkBuilder: (id) => {
      return routes.user.path.replace(':id', id.toString());
    },
  },
};

interface SearchResultType {
  name: string;
  linkBuilder: (id: string) => string;
}
