import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    Box,
    Heading,
    Textarea,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Text
} from '@chakra-ui/react';
import { UnitComment, Order, Unit } from 'models/ApiModels';
import { delApi, postApi, fetchApi } from 'lib/fetchApi';
import { useApi } from 'lib/useApi';
import { useFirebaseUser } from 'lib/useFirebaseUser';

interface CommentsProps {
    unitId: number
    orderId?: number
}

const Comments = ({ unitId, }: CommentsProps): JSX.Element => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [comments, setComments] = useState([]);
    const [commentId, setCommentId] = useState(0);
    const [fetchString, setFetchString] = useState<string>(`/comments/unit/${unitId}`)
    const [activeId, setActiveId] = useState<number>(unitId)
    const { data: unit, reload: reloadUnit } = useApi<Unit>(`/units/${unitId}`);
    const { data: orders } = useApi<Order[]>(`/orders/unit/${unitId}`);
    const commentRef = useRef(null);
    const cancelRef = useRef(null)

    const user = useFirebaseUser();

    useEffect(() => {
        if (orders && orders.length > 0) {
            setFetchString(`/comments/order/${orders[orders.length - 1].id}`);
            setActiveId(orders[orders.length - 1].id,)
        }
        const getComments = () =>
            fetchApi(fetchString);

        getComments()
            .then((res) => res.json())
            .then((data) => data !== undefined && setComments(data))
            .catch((err) => console.log(err));
    }, [orders, unit]);

    const removeComment = async (id: number) => {
        console.log(id, 'id')
        await delApi(`/comments/${id}`);
        setNewComments();
        setCommentId(0);
        setIsAlertOpen(false);
    };

    async function AddComment(comment: string): Promise<boolean> {
        const data = {
            unitId,
            createdByUserId: user.email,
            text: comment,
            orderId: 0
        }

        if (orders && orders.length > 0) {
            data['orderId'] = orders[orders.length - 1].id
        }
        console.log(data)
        const addComment = await postApi('/Comments', {
            ...data
        });
        if (addComment.ok) {
            setNewComments();
            commentRef.current.value = '';
            return true;
        }
        return false;
    }

    const setNewComments = async () => {
        await fetchApi(fetchString).then((res) => res.json())
            .then((data) => { data !== undefined && setComments(data) })
    }

    return (
        <>
            <Box maxW={1000}>
                {comments.length > 0 && (
                    <Heading pt={4} size="md">
                        Kommentarer (senaste eller aktiv order)
                    </Heading>
                )}
                {comments.map((comment: UnitComment) => {
                    const newDate = new Date(comment.createdAt);
                    newDate.setHours(newDate.getHours() + 2)
                    return (
                        <Box my={5} key={comment.id}>
                            <hr />
                            <Box style={{ display: 'flex' }}>
                                <Box key={comment.id} my={3} flexGrow="1" flexWrap="wrap">
                                    {comment.text}
                                </Box>
                                <Box fontSize={12} textAlign={'right'} mt={1} ml={10} minW="max-content" display="flex" >
                                    <Text mt={1}>
                                        {newDate.toLocaleDateString('sv-SE')}{" "}{newDate.toLocaleTimeString('sv-SE')}
                                    </Text>
                                    <Button
                                        ml="3"
                                        size="xs"
                                        variant="secondary"
                                        onClick={() => { setCommentId(comment.id); setIsAlertOpen(true) }}>
                                        Ta bort
                                    </Button>
                                </Box>
                            </Box>
                            <hr />
                        </Box>
                    );
                })}
                <Heading pt={4} size="md">
                    Ny kommentar
                </Heading>
                <Textarea mt={4} placeholder="Kommentarstext" ref={commentRef} />
                <Box justifyContent="flex-end" display="flex" mt="3">
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                            AddComment(commentRef.current.value as string);
                        }}>
                        Lägg till
                    </Button>
                </Box>
            </Box>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setIsAlertOpen(false);
                }}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Ta bort kommentar
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Är du säker på att du vill ta bort den här kommentaren?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button variant="secondary"
                            ref={cancelRef}
                            onClick={() => {
                                setIsAlertOpen(false);
                            }}>
                            Gå tillbaka
                        </Button>
                        <Button variant="primary" onClick={() => removeComment(commentId)} ml={3}>
                            Ta bort
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

        </>
    );
};

export default Comments;
