import React from 'react';
import ReactDOM from 'react-dom';
import './style/variables.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'style/table.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import { customIcons } from 'static/custom-icons';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors } from 'lib/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyCH-ptDZxosyg0WqXZBMLH7UZhU1WRwgjM',
  authDomain: 'mitt-lager.firebaseapp.com',
  databaseURL: 'https://mitt-lager.firebaseio.com',
  projectId: 'mitt-lager',
  storageBucket: 'mitt-lager.appspot.com',
  messagingSenderId: '689064215131',
  appId: '1:689064215131:web:b604e25affe15d34ed757e',
};

firebase.initializeApp(firebaseConfig);
firebase.auth();

console.log(process.env.REACT_APP_ENV);

export const theme = extendTheme({
  fonts: {
    heading: `Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `"Roboto Mono", SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 8,
        fontWeight: 400,
        _hover: {
          bg: colors.secondary[900],
          color: colors.white,
        }
      },
      variants: {
        darkSolid: {
          bg: colors.neutrals[900],
          color: colors.white,
          border: `1px ${colors.secondary[900]} solid`,
          _hover: {
            bg: colors.white,
            color: colors.secondary[900],
            border: `1px ${colors.secondary[900]} solid`,
          }
        },
        primary: {
          bg: colors.primary[900],
          color: colors.white,
          _hover: {
            bg: colors.secondary[900],
          },
        },
        primaryWhite: {
          bg: colors.white,
          color: colors.primary[900],
          border: `1px ${colors.primary[900]} solid`,
        }
      },
      secondary: {
        color: colors.primary[900],
        bg: colors.white,
        _hover: {
          bg: colors.secondary[900],
          color: colors.white,
        }
      },
      solid: {
        bg: colors.system.error[900],
        color: colors.white,
      },
      ml: {
        bg: colors.primary[900],
        color: colors.white,
        _hover: { bg: colors.primary.hover },
      },
      success: {
        bg: colors.system.success[900],
        color: colors.white,
      },
      outline: {
        color: colors.black,
        bg: colors.white,
        border: '1px solid black',
        borderColor: 'black',
      },
    },
  },
},
);
const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      50: '#ffebdf',
      100: '#ffcab3',
      200: '#faa885',
      300: '#f68656',
      400: '#f26427',
      500: '#d84a0d',
      600: '#a93908',
      700: '#792805',
      800: '#4a1700',
      900: '#1f0400',
    },
  },
  icons: {
    ...customIcons,
  },
};
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
