import { Container } from 'components/Container';
import { facilitiesTable } from 'components/DataTable/DataTableModels';
import { IconCard } from 'components/IconCard';
import React from 'react';
import { routes } from '../../routes';
import userIcon from 'static/images/user_icon.svg';
import { PortalContext } from 'PortalContext';
import { DataTable } from 'components/DataTable/DataTable';
import { useApi } from 'lib/useApi';
import { useParams } from 'react-router-dom';
import { Facility, Place } from 'models/ApiModels';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ContainerHead } from 'components/ContainerHead';

export function PlacePage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: facilitiesResp, state: respState } = useApi<Facility[]>(
    `/facilities/place/${id}`
  );
  const { data: placeResp } = useApi<any>(`/places/${id}`)
  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <>
      {placeResp && <ContainerHead children="" title={`${placeResp.name}` } />}
      <Container>
        <Link to={routes.editPlace.path.replace(':id', id)}>
          <Button variant="primary" mb={10}>{routes.editPlace.linkText}</Button>
        </Link>
        <DataTable
          title="Anläggningar"
          tableDef={facilitiesTable}
          data={facilitiesResp}
          showSearch={false}
          searchableFields={[]}
        />
      </Container>
    </>
  );
}
