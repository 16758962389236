const colors = {
  black: '#000000',
  white: '#ffffff',
  primary: {
    900: '#F27039',
    700: '#ED8B6B',
    500: '#F5B39C',
    300: '#FED9CD',
    100: '#FFEDE8',
    hover: '#D05E38',
  },
  secondary: {
    50: '#F6F6F6',
    900: '#2E2E2E',
    700: '#404040',
    500: '#E4E4E4',
    300: '#F0F0F0',
    darkGrey: '#484848',
    lightGrey: '#d4d4d4',
  },
  neutrals: {
    900: '#333333',
    700: '#494A4D',
    500: '#797B80',
    300: '#AAADB2',
    100: '#DADEE5',
  },
  system: {
    error: {
      900: '#FF595E',
      700: '#FF7A7E',
      500: '#FF9497',
      300: '#FFB2B4',
      100: '#FFD5D7',
    },
    warning: {
      900: '#FFBC0A',
      700: '#FFC93B',
      500: '#FFD566',
      300: '#FFDD84',
      100: '#FFEEC2',
    },
    success: {
      900: '#80AF5A',
      700: '#99BF7B',
      500: '#B3CF9C',
      300: '#CCDFBD',
      100: '#E6EFDE',
    },
  },
};

const header = {
  semiBoldMoney: {
    fontFamily: 'Roboto Mono, monospace',
    fontStyle: 'Normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '37px',
  } as React.CSSProperties,
  bigBoldMoney: {
    fontFamily: 'Roboto Mono, monospace',
    fontStyle: 'Normal',
    fontWeight: 'bold',
    fontSize: '80px',
    lineHeight: '106px',
  } as React.CSSProperties,
  semiBold: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    color: colors.secondary.darkGrey,
  } as React.CSSProperties,
};

const elevation = {
  '-40':
    '0px -10px 20px rgba(0, 0, 0, 0.04), 0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '-20':
    '0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '0': '0',
  '+20':
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '+40':
    '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '+60':
    '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '+80':
    '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
};

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

export { colors, elevation, apiBaseUrl, header };
