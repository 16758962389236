import React from 'react';
import { unitsTable } from 'components/DataTable/DataTableModels';
import { createProductUnit, routes } from 'routes';
import userIcon from 'static/images/user_icon.svg';
import { Container } from 'components/Container';
import { IconCard } from 'components/IconCard';
import { DataTable } from 'components/DataTable/DataTable';
import { useApi } from 'lib/useApi';
import { PortalContext } from 'PortalContext';
import { Link, useParams } from 'react-router-dom';
import { ContainerHead } from 'components/ContainerHead';
import { DataContainer } from 'components/DataContainer';
import { Product, ProductTypes, Unit } from 'models/ApiModels';
import { Flex, Button, Text } from '@chakra-ui/react';
import { Icon } from 'components/Icon';
import { KeyIcon } from 'lib/IconLib';
import { AddIcon } from '@chakra-ui/icons';

export function ProductPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: unitsResp, state: respState } = useApi<Unit[]>(
    `/units/product/${id}`
  );
  const { data: product } = useApi<Product>(`/products/${id}`);
  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <>
      <ContainerHead title="Produktartiklar">
        <DataContainer title="Namn">
          {product?.name ?? 'Inget namn'}
        </DataContainer>
        <DataContainer title="Beskrivning">
          {product?.description !== ''
            ? product?.description
            : 'Ingen beskrivning'}
        </DataContainer>
        <DataContainer title="Månadspris">
          {product?.price ?? '0'}:-
        </DataContainer>
        <DataContainer title="Produkttyp">
          {ProductTypes[product?.type ?? 0]}
        </DataContainer>
        <DataContainer title="Storlek">{product?.size ?? '0'} m<sup>2</sup></DataContainer>
      </ContainerHead>
      <Container>
        <Flex mb={5}>
          <Link to={routes.editProduct.path.replace(':id', id)}>
            <Button variant="primary"><Icon fill="white" icon={KeyIcon} /><Text ml={3}>{routes.editProduct.linkText}</Text></Button>
          </Link>
          <Link to={createProductUnit.path.replace(':id', id)}>
            <Button variant="primary" ml={5}><AddIcon /><Text ml={3}>{createProductUnit.linkText}</Text></Button>
          </Link>
        </Flex>
        <DataTable
          title="Artiklar"
          tableDef={unitsTable}
          data={unitsResp}
          showSearch={true}
          searchableFields={[
            'status',
            'objectID',
            'externalId',
            'product.size',
          ]}
        />
      </Container>
    </>
  );
}
