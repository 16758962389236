/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import resolve from 'lib/resolve';
import React from 'react';
import { Link } from 'react-router-dom';
import { TableDefinition } from './DataTableModels';

export default function TableHits({
  hits,
  tableDef,
}: {
  hits: Array<any>;
  tableDef: TableDefinition;
}): JSX.Element {
  function getDataPoint(hit: any, column: any): JSX.Element {
    if (tableDef.noLinks) {
      return <span>{resolve(hit, column.accessor)}</span>;
    }

    return (
      <Link
        to={`${tableDef.overrideViewRowUrl || window.location.pathname}/${
          hit.objectID ?? hit.id
        }`}>
        {resolve(hit, column.accessor)}
      </Link>
    );
  }


  return (
    <Box>
      <table className="data-table">
        <thead>
          <tr>
            {tableDef.columns.map((column) => (
              <th key={column.header}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hits.map((hit, i) => {
            return (
              <tr
                key={hit.objectID || hit.id || i}
                style={{
                  ...(tableDef.applyRowStyle
                    ? tableDef.applyRowStyle(hit)
                    : undefined),
                  ...tableDef.rowStyles,
                }}>
                {tableDef.columns.map((column) => (
                  <td key={column.accessor} style={column.tdStyles}>
                    {column.customHtml
                      ? column.customHtml(hit)
                      : getDataPoint(hit, column)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}
