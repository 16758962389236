import { Box, Spinner } from '@chakra-ui/react';
import { PortalContext } from 'PortalContext';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { routesList, securitasRoutesList } from 'routes';
import firebase from 'firebase/app';

export function MainContentContainer(): JSX.Element {
  const { pathname } = useLocation();
  const portalContext = React.useContext(PortalContext);
  const [isSecuritas, setIsSecuritas] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    async function getClaims(): Promise<void> {
      setIsSecuritas(!!firebase.auth().currentUser?.email?.includes('@securitas.se'));
    }
    getClaims();
  }, []);
  return (
    <Box className="main-container">
      <Box className="main-content" style={{ position: 'relative' }}>
        {portalContext.state.pageState === 'loading' && (
          <div className="main-content-loader">
            <Spinner />
          </div>
        )}
        <Switch>
          {!isSecuritas ? routesList.map((x) => (
            <Route
              exact
              key={x.path}
              path={x.path}
              render={() => <x.component {...x.pageProps}></x.component>}
            />
          )) : securitasRoutesList.map((x) => (
            <Route
            exact
            key={x.path}
            path={x.path}
            render={() => <x.component {...x.pageProps}></x.component>}
          />
          ))
         }
        </Switch>
      </Box>
    </Box>
  );
}
