import React from 'react';

interface PortalContextState {
  pageState: string;
}

const defaultState = {
  pageState: '',
};

type AppContextAction = { payload: unknown; type: string };

interface AppContext {
  state: PortalContextState;
  dispatch: React.Dispatch<AppContextAction>;
}

interface ReducerState extends PortalContextState {
  pageState: string;
}

function reducer(
  state: PortalContextState,
  action: AppContextAction
): ReducerState {
  switch (action.type) {
    case 'SET_PAGE_STATE': {
      return { ...state, pageState: action.payload as string };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function PortalContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <PortalContext.Provider value={{ state, dispatch }}>
      {children}
    </PortalContext.Provider>
  );
}

export const PortalContext = React.createContext<AppContext>({
  state: defaultState,
  dispatch: () => null,
});
