import React from 'react';
import { FacilitiesPage } from './pages/FacilitiesPage';
import { FacilityPage } from './pages/FacilityPage';
import { PlacesPage } from './pages/Places/PlacesPage';
import { StartPage } from './pages/StartPage';
import { ProductsPage } from 'pages/Products/ProductsPage';
import { UsersPage } from 'pages/Users/UsersPage';
import { FunctionComponent } from 'react';
import { UserPage } from 'pages/Users/UserPage';
import OrderPage from 'pages/Orders/Order';
import { CreatePage } from 'pages/CreatePage';
import UnitPage from 'pages/Units/UnitPage';
import { ProductTypes, UnitStatus } from 'models/ApiModels';
import { EditPage } from 'pages/EditPage';
import { SuperAdminPage } from 'pages/SuperAdminPage';
import { PlacePage } from 'pages/Places/PlacePage';
import { ProductPage } from 'pages/Products/ProductPage';
import { SearchPage } from 'pages/SearchPage';
import { CampaignsPage } from 'pages/Campaigns/CampaignsPage';
import { AddCampaignsPage } from 'pages/Campaigns/AddCampaignPage';
import { EditCampaignpage } from 'pages/Campaigns/EditCampaignpage'
import {
  RootIcon,
  UsersIcon,
  ProductsIcon,
  FacilitiesIcon,
  PlacesIcon,
  OrdersIcon,
  LateIcon,
  KeyIcon,
  CampaignIcon
} from 'lib/IconLib';
import {
  ArraySelect,
  FormCustomDropdown,
  FormCustomMultiInput,
  FormHidden,
  FormInput,
  FormInputNumber,
  FormSelect,
  FormTextarea,
} from 'lib/form';
import { OrdersPage } from 'pages/Orders/OrdersPage';
import { NotificationsPage } from 'pages/NotificationsPage';
import { DuePaymentsPage } from 'pages/DuePayments';

const root: Route = {
  path: '/',
  component: StartPage,
  linkText: 'Dashboard',
  icon: RootIcon,
};

const order: Route = {
  path: '/ordrar/:id',
  component: OrderPage,
  linkText: 'Order',
};

const orders: Route = {
  path: '/ordrar',
  component: OrdersPage,
  linkText: 'Ordrar',
  icon: OrdersIcon,
};

const notifications: Route = {
  path: '/notifieringar',
  component: NotificationsPage,
  linkText: 'Notifieringar',
};

const createUser: CreatePageRoute = {
  path: '/konton/skapa',
  component: CreatePage,
  linkText: 'Konto',
  pageProps: {
    title: 'Skapa användare',
    postUrl: '/users/create',
    successTitle: 'Konto skapat',
    redirectUrl: '/konto/:id',
    fields: (): JSX.Element[] => [
      <FormInput
        label="Email"
        name="email"
        isRequired
        placeholder="tex 'example@example.com'"
      />,
    ],
  },
};

const user: Route = {
  path: '/konton/:id',
  component: UserPage,
  linkText: 'Konto',
};

// TODO
const unit: Route = {
  path: '/objekt/:id',
  component: UnitPage,
  linkText: 'Objekt',
};

// TODO
export const editFacilityUnit: EditPageRoute = {
  path: '/objekt/:id/edit',
  component: EditPage,
  linkText: 'Ändra artikel',
  pageProps: {
    title: 'Ändra artikel',
    getUrl: '/units/:id',
    postUrl: '/units/update',
    redirectUrl: '/objekt/:id',
    successTitle: 'Anläggning sparad',
    fields: (params: {
      productId: string;
      facilityId: string;
      status: string;
      id: string;
    }): JSX.Element[] => [
        <FormInput
          label={'Externt Id'}
          name={'externalId'}
          isRequired={true}
          placeholder={'40001-01-0001'}
        />,
        <FormSelect
          label="Produkt"
          name="productId"
          isRequired
          placeholder="Koppla till produkt"
          itemsUrl="/products"
          selected={params.productId}
        />,
        <FormSelect
          label="Anläggning"
          name="facilityId"
          isRequired
          placeholder="Koppla till anläggning"
          itemsUrl="/facilities"
          selected={params.facilityId}
        />,
        <FormCustomDropdown
          label="Status"
          name="status"
          isRequired
          placeholder="Status"
          items={Object.values(UnitStatus)}
          selected={params.status}
        />,
        <FormHidden name="id" data={params.id} />,
      ],
  },
};

const users: Route = {
  path: '/konton',
  component: UsersPage,
  linkText: 'Konton',
  icon: UsersIcon,
};

const createProduct: CreatePageRoute = {
  path: '/produkter/skapa',
  component: CreatePage,
  linkText: 'Skapa produkt',
  pageProps: {
    title: 'Skapa produkt',
    postUrl: '/products/add',
    successTitle: 'Produkt skapad',
    fields: (): JSX.Element[] => [
      <FormInput
        label="Namn"
        name="name"
        isRequired
        placeholder="tex 'Sorken'"
      />,
      <FormInputNumber label="Pris (SEK)" name="price" isRequired value={0} />,
      <FormInputNumber
        label="Storlek (kvm)"
        name="size"
        step={0.1}
        min={0.1}
        isRequired
        value={0}
      />,
      <ArraySelect
        label={'Typ'}
        name={'stringType'}
        placeholder={'Produkttyp'}
        items={ProductTypes}
        isRequired={true}
      />,
      <FormInput
        label="Beskrivning"
        name="description"
        placeholder="tex 'Mellanstor artikel, passar för att lagra möbler ifrån en 2:a'"
      />,
    ],
  },
};

const editProduct: EditPageRoute = {
  path: '/produkter/:id/edit',
  component: EditPage,
  linkText: 'Ändra produkt',
  pageProps: {
    title: 'Ändra produkt',
    getUrl: '/products/:id',
    postUrl: '/products/update',
    redirectUrl: '/produkter/:id',
    successTitle: 'Plats sparad',
    fields: (params: {
      price: number;
      size: number;
      description: string;
      id: string;
      type: string;
    }): JSX.Element[] => [
        <FormHidden name="id" data={params.id} />,
        <FormInput
          label={'Namn'}
          name={'name'}
          isRequired={true}
          placeholder={'Namn på produkten...'}
        />,
        <FormInputNumber
          label="Pris (SEK)"
          name="price"
          isRequired
          value={params.price}
        />,
        <FormInputNumber
          label="Storlek (kvm)"
          name="size"
          step={0.01}
          min={0.1}
          isRequired
          value={params.size}
        />,
        <ArraySelect
          label={'Typ'}
          name={'stringType'}
          placeholder={'Produkttyp'}
          items={ProductTypes}
          isRequired={true}
          selected={parseInt(params.type)}
        />,
        <FormTextarea
          label={'Beskrivning'}
          name={'description'}
          placeholder={'Beskrivning'}
          value={params.description}
        />,
        <FormHidden name="id" data={params.id} />,
      ],
  },
};

const product: Route = {
  path: '/produkter/:id',
  component: ProductPage,
  linkText: 'Produkt',
  icon: ProductsIcon,
};

const products: Route = {
  path: '/produkter',
  component: ProductsPage,
  linkText: 'Produkter',
  icon: ProductsIcon,
};

const campaigns: Route = {
  path: '/kampanjer',
  component: CampaignsPage,
  linkText: 'Kampanjer',
  icon: CampaignIcon
}

const createCampaign: Route = {
  path: '/kampanjer/skapa',
  component: AddCampaignsPage,
  linkText: 'Kampanjer',
  icon: CampaignIcon
}

const editCampaign: Route = {
  path: '/kampanjer/edit/:id',
  component: EditCampaignpage,
  linkText: 'Ändra kampanj',
  icon: CampaignIcon
}

export const createFacilityUnit: CreatePageRoute = {
  path: '/anlaggningar/:id/skapa-forrad',
  component: CreatePage,
  linkText: 'Skapa artikel',
  pageProps: {
    title: 'Skapa artikel',
    postUrl: '/units/add',
    successTitle: 'artikel skapat',
    fields: (params: { id: string }): JSX.Element[] => {
      return [
        <FormSelect
          label="Produkt"
          name="productId"
          isRequired
          placeholder="Koppla till produkt"
          itemsUrl="/products"
        />,
        <FormInput label="Externt Id" name="externalId" isRequired />,
        <FormCustomDropdown
          label="Status"
          name="status"
          isRequired
          placeholder="Status"
          items={Object.values(UnitStatus)}
        />,
        <FormHidden name="facilityId" data={params.id} />,
      ];
    },
  },
};

export const createProductUnit: CreatePageRoute = {
  path: '/produkter/:id/skapa-forrad',
  component: CreatePage,
  linkText: 'Skapa Artikel',
  pageProps: {
    title: 'Skapa Artikel',
    postUrl: '/units/add',
    successTitle: 'artikel skapat',
    fields: (params: { id: string }): JSX.Element[] => {
      return [
        <FormSelect
          label="Anläggningar"
          name="facilityId"
          isRequired
          placeholder="Koppla till anläggning"
          itemsUrl="/facilities"
        />,
        <FormInput label="Externt Id" name="externalId" isRequired />,
        <FormCustomDropdown
          label="Status"
          name="status"
          isRequired
          placeholder="Status"
          items={Object.values(UnitStatus)}
        />,
        <FormHidden name="productId" data={params.id} />,
      ];
    },
  },
};

const createFacility: CreatePageRoute = {
  path: '/anlaggningar/skapa',
  component: CreatePage,
  linkText: 'Skapa anläggning',
  pageProps: {
    title: 'Skapa anläggning',
    postUrl: '/facilities/add',
    successTitle: 'Anläggning skapad',
    redirectUrl: '/anlaggningar/:id',
    fields: (): JSX.Element[] => [
      <FormInput
        label="Namn"
        name="name"
        isRequired
        placeholder="tex 'Sorken'"
      />,
      <FormInput
        label="Externt ID"
        name="externalId"
        isRequired
        placeholder="tex '400001'"
      />,
      <FormInput
        label="Address"
        name="address"
        isRequired
        placeholder="tex 'Birkagatan 38, 561 33 Huskvarna'"
      />,
      <FormInput
        label="Vaka(Axema) IP"
        name="vakaIP"
        isRequired
        placeholder="IP till Vaka ex '127.0.01'"
      />,
      <FormCustomMultiInput
        name="EntryInstructions"
        label="Instruktioner för anläggning"
        items={[
          'Inne i lokalen direkt till höger finner du en grön safebox på väggen.',
          'Slå kod 1907A på safeboxen och öppna denna och ta ut ett lås med tillhörande nycklar.',
        ]}
        placeholder="Ny instruktion"
        isRequired
      />,
      <FormSelect
        label="Stad"
        name="locationId"
        isRequired
        placeholder="Koppla till plats"
        itemsUrl="/places"
      />,
    ],
  },
};

const facilities: Route = {
  path: '/anlaggningar',
  component: FacilitiesPage,
  linkText: 'Anläggningar',
  icon: FacilitiesIcon,
};

const facility: Route = {
  path: '/anlaggningar/:id',
  component: FacilityPage,
  linkText: 'Anläggningar',
};

export const editFacility: EditPageRoute = {
  path: '/anlaggningar/:id/edit',
  component: EditPage,
  linkText: 'Ändra anläggning',
  pageProps: {
    title: 'Ändra anläggning',
    getUrl: '/facilities/:id',
    postUrl: '/facilities/update',
    successTitle: 'Anläggning sparad',
    redirectUrl: '/anlaggningar/:id',
    fields: (params: {
      id: string;
      locationId: string;
      externalId: string;
      entryInstructionsArray: string[];
      slug: string;
    }): JSX.Element[] => [
        <FormInput
          label={'Namn'}
          name={'name'}
          isRequired={true}
          placeholder={'Namn på platsen...'}
        />,
        <FormInput
          label="Address"
          name="address"
          isRequired
          placeholder="tex 'Birkagatan 38, 561 33 Huskvarna'"
        />,
        <FormSelect
          label="Plats"
          name="locationId"
          selected={params.locationId}
          isRequired
          placeholder="Koppla till plats"
          itemsUrl="/places"
        />,
        <FormCustomMultiInput
          name="EntryInstructions"
          label="Instruktioner för anläggning"
          items={params.entryInstructionsArray}
          placeholder="Ny instruktion"
          isRequired
        />,
        <FormInput
          label={'Vaka(Axema) IP'}
          name={'vakaIP'}
          isRequired={true}
          placeholder="IP till Vaka ex '127.0.01'"
        />,
        <FormHidden name="id" data={params.id} />,
        <FormHidden name="slug" data={params.slug} />,
        <FormHidden name="externalId" data={params.externalId} />,
      ],
  },
};

const places: Route = {
  path: '/platser',
  component: PlacesPage,
  linkText: 'Platser',
  icon: PlacesIcon,
};

const place: Route = {
  path: '/platser/:id',
  component: PlacePage,
  linkText: 'Plats',
  icon: PlacesIcon,
};

const createPlace: CreatePageRoute = {
  path: '/platser/skapa',
  component: CreatePage,
  linkText: 'Ny plats',
  pageProps: {
    title: 'Skapa plats',
    postUrl: '/places/add',
    successTitle: 'Plats skapad',
    fields: (): JSX.Element[] => [
      <FormInput
        label={'Namn'}
        name={'name'}
        isRequired={true}
        placeholder={'Namn på platsen...'}
      />,
    ],
  },
};

const editPlace: EditPageRoute = {
  path: '/platser/:id/edit',
  component: EditPage,
  linkText: 'Ändra plats',
  pageProps: {
    title: 'Ändra plats',
    getUrl: '/places/:id',
    postUrl: '/places/update',
    successTitle: 'Plats sparad',
    redirectUrl: '/platser/:id',
    fields: (params: { id: string }): JSX.Element[] => [
      <FormInput
        label={'Namn'}
        name={'name'}
        isRequired={true}
        placeholder={'Namn på platsen...'}
      />,
      <FormHidden name="id" data={params.id} />,
    ],
  },
};

export const superAdmin: Route = {
  path: '/superadmin',
  component: SuperAdminPage,
  linkText: 'Superadmin',
  icon: KeyIcon
};

const duePayments: Route = {
  path: '/sena-betalningar/',
  component: DuePaymentsPage,
  linkText: 'Sena betalningar',
  icon: LateIcon,
};

export const search: Route = {
  path: '/search',
  component: SearchPage,
  linkText: 'Sök',
};

export const routes: { [key: string]: Route } = {
  root,
  superAdmin,
  search,
  duePayments,

  facilities,
  users,
  places,
  products,
  campaigns,
  createCampaign,
  editCampaign,
  notifications,
  orders,

  createPlace,
  createProduct,
  createFacility,
  createFacilityUnit,
  createProductUnit,
  createUser,

  editPlace,
  editProduct,
  editFacility,
  editFacilityUnit,

  facility,
  user,
  product,
  place,
  order,
  unit,
};

export const securitasRoutes: { [key: string]: Route } = {
  users,
  places,
  orders,

  user,
  place,
  order,
  unit,
};

export const mainMenuRoutes: Route[] = [
  root,
  users,
  products,
  facilities,
  places,
  orders,
  campaigns,
  duePayments,
];
export const mainMenuRoutesSecuritas: Route[] = [

  users,

];

export const routesList: Route[] = Object.values(routes);
export const securitasRoutesList: Route[] = Object.values(securitasRoutes);

interface Route {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => JSX.Element;
  linkText: string;
  icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  pageProps?: PageProps;
}

interface CreatePageRoute extends Route {
  pageProps?: CreatePageProps;
}

interface EditPageRoute extends Route {
  pageProps?: EditPageProps;
  redirectUrl?: string;
}

interface PageProps {
  title: string;
}

export interface CreatePageProps extends PageProps {
  fields(params: Record<string, string | number | string[]>): JSX.Element[];
  postUrl: string;
  successTitle: string;
  redirectUrl?: string;
}

export interface EditPageProps extends CreatePageProps {
  getUrl: string;
}
