import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { postApi } from 'lib/fetchApi';
import { ArrowDown, ArrowUp } from 'lib/IconLib';
import { Unit } from 'models/ApiModels';
import React, { useState } from 'react';
import { Icon } from './Icon';
interface Props extends BoxProps {
  unit: Unit;
}
export function SendUnitCheckoutLink(props: Props): JSX.Element {
  const [email, setEmail] = useState('');
  const [price, setPrice] = useState(props.unit.product.price);
  const [show, setShow] = useState(false);
  const toast = useToast();

  function handleToggle(): void {
    setShow(!show);
  }

  async function SendEmail() {
    const orderRequest = {
      status: '',
      externalId: props.unit.externalId,
      orderArticle: '007',
      orderType: 'Hyra Lager',
      orderAmount: price.toString(),
      orderAmountVAT: '0',
      transferred: 1,
      orderEmail: email,
      facilityId: props.unit.facilityId,
      productId: props.unit.productId,
      newCustomer: true,
    };
    const res = await postApi(`/orders/sendLink`, orderRequest);
    if (res.ok) {
      toast({
        title: 'Länk till ordern har skickats till ' + email,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } else {
      toast({
        title: 'Något gick fel med att skicka länk till ' + email,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }
  return (
    <Flex direction="column" fontFamily="Montserrat" mb={12} maxW="500px">
      <Box
        _hover={{ bg: '#F6F6F6', cursor: 'pointer' }}
        fontWeight="bold"
        flex="flex"
        p={4}
        width="100%"
        height="64px"
        onClick={handleToggle}
        borderBottom="1px solid #AFAFAF">
        Skicka bokningsmejl för den här enheten
        <span style={{ float: 'right' }}>
          {!show ? (
            <Icon fill="#333" icon={ArrowDown} />
          ) : (
            <Icon fill="#333" icon={ArrowUp} />
          )}
        </span>
      </Box>
      <Collapse unmountOnExit={true} in={show} >
        <Box overflow="auto" maxH="500px" maxW="500px" pt={8}>
          <Box display="flex" flexDirection="column" {...props}>
            <Box display="flex" flexDirection="column">
              <Text>E-post</Text>
              <Input
                placeholder="E-post att skicka till"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Text pt={2}>Kostnad</Text>
              <Input
                type="number"
                placeholder="Pris"
                name="price"
                value={price}
                onChange={(e) => {
                  setPrice((e.target.value as unknown) as number);
                }}
              />
              <Box style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  mt={4}
                  variant="primary"
                  w="fit-content"
                  onClick={() => {
                    SendEmail();
                  }}>
                  Skicka
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Flex>
  );
}
