import firebase from 'firebase/app';

async function _fetchApi(
  appendApiPath: boolean,
  path: string,
  options: RequestInit,
  body?: string,
  authenticate = true
): Promise<Response> {
  options.headers = new Headers();
  options.headers.set('Accept', 'application/json');
  options.headers.set('Content-Type', 'application/json');

  if (authenticate) {
    const user = await firebase.auth().currentUser;
    const token = await user?.getIdToken();
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  if (body) {
    options.body = body;
  }

  let baseUrl = process.env.REACT_APP_API_BASE;
  if (!appendApiPath) {
    baseUrl = baseUrl?.split('/api/')[0];
  }

  const response = await fetch(baseUrl + path, options);

  return response;
}

export async function fetchApi(path: string): Promise<Response> {
  return _fetchApi(true, path, { method: 'GET' });
}

export async function postApi(path: string, data: unknown): Promise<Response> {
  return _fetchApi(true, path, { method: 'POST' }, JSON.stringify(data));
}

export async function patchApi(path: string, data: unknown): Promise<Response> {
  return _fetchApi(true, path, { method: 'PATCH' }, JSON.stringify(data));
}

export async function delApi(path: string, data?: unknown): Promise<Response> {
  return _fetchApi(true, path, { method: 'DELETE' }, JSON.stringify(data));
}

export async function fetchBackend(path: string): Promise<Response> {
  return _fetchApi(false, path, { method: 'GET' });
}

