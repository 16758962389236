export const convertIdToStringArray = (
  idArr: number[],
  appliesToAll: boolean,
  allContentString: string,
  contentArr: any[]
): string[] => {
  let returnArr = [];
  if (appliesToAll) {
    returnArr = contentArr.map(x => x.id)
  } else {
    returnArr = contentArr
      .filter((content) => content.id && idArr.includes(content.id))
      .map((x) => x.name);
  }
  return returnArr;
};
