import React, { FormEvent } from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { SidebarLink } from './SidebarLink';
import { mainMenuRoutes, mainMenuRoutesSecuritas, routes, superAdmin } from '../../routes';
import sidebar from 'style/sidebar.module.css';
import firebase from 'firebase/app';
import { KeyIcon } from 'lib/IconLib';
import { SearchIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

export function Sidebar(): JSX.Element {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isSecuritas, setIsSecuritas] = React.useState(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname !== routes.search.path && searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, [location.pathname]);

  function onSearch(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (location.pathname === routes.search.path) return; // uses onChange instead

    const query = searchInputRef.current?.value.trim();

    history.push({
      pathname: routes.search.path,
      search: `?q=${query}`,
    });
  }

  function onSearchInput(event: FormEvent<HTMLInputElement>): void {
    if (location.pathname !== routes.search.path) return;

    const query = event?.currentTarget.value.trim();

    history.replace({
      pathname: routes.search.path,
      search: `?q=${query}`,
    });
  }

  React.useEffect(() => {
    return document.addEventListener('keydown', function (event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        searchInputRef.current?.focus();
        searchInputRef.current?.select();
      }
    });
  }, []);

  React.useEffect(() => {
    async function getClaims(): Promise<void> {
      setIsAdmin(!!firebase.auth().currentUser?.email?.includes('@netmine.se'));
      setIsSecuritas(!!firebase.auth().currentUser?.email?.includes('@securitas.se'));
    }
    getClaims();
  }, []);

  return (
    <Flex as="aside" className={sidebar.sidebar} flexDir="column">
      <Box
        pos={{ base: 'static', md: 'sticky' }}
        top={{ base: 0, md: '5.5em' }}>
        <Box
          pb={4}
          display={{
            base: 'block',
            lg: 'none',
          }}>
          <form onSubmit={onSearch}>
            <InputGroup className="search-input" mr={4} pl={2}>
              <InputRightElement
                borderLeft="1px solid #2d2d2d"
                className="search-input-icon"
                children={<SearchIcon boxSize="1.2em" color={'#F27039'} />}
              />
              <Input
                ref={searchInputRef}
                backgroundColor="#393539"
                border="1px solid"
                borderColor="#2d2d2d"
                type="search"
                onChange={onSearchInput}
                placeholder="Sök (cmd+k / ctrl+k)"
              />
            </InputGroup>
          </form>
        </Box>
        {!isSecuritas ? mainMenuRoutes.map((x) => (
          <SidebarLink
            key={x.path}
            href={x.path}
            title={x.linkText}
            icon={x.icon}
          />
        )) : mainMenuRoutesSecuritas.map((x) => (
          <SidebarLink
          key={x.path}
          href={x.path}
          title={x.linkText}
          icon={x.icon}
          />
          ))}
        {isAdmin && (
          <SidebarLink
            key={superAdmin.linkText}
            href={superAdmin.path}
            title={superAdmin.linkText}
            icon={KeyIcon}
          />
        )}
      </Box>
    </Flex>
  );
}
