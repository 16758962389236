import { CheckIcon } from '@chakra-ui/icons';
import { Button, Flex, useToast, Text } from '@chakra-ui/react';
import { fetchApi } from 'lib/fetchApi';
import React, { useState } from 'react';

interface SyncVakaProps {
  orderId: number;
}

export function SyncVakaPin(props: SyncVakaProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useToast();

  async function _onClick() {
    setIsLoading(true);
    const result = await fetchApi(`/orders/${props.orderId}/sync`);
    setIsLoading(false);
    if (result.ok) {
      setErrorMessage('');
      toast({
        title: 'Koden har synkats!',
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } else {
      setErrorMessage('Något gick fel, försök igen senare');
      setTimeout(() => { setErrorMessage("") }, 5000)
    }
  }

  return (
    <Flex flexDirection="column" position="relative" pb={3}>
      <Button
        width="fit-content"
        onClick={_onClick}
        variant="primary"
        isLoading={isLoading}
        type="submit">
        <CheckIcon mr="3" />
        Synka kod till Vaka
      </Button>
      <Text position="absolute" top="45px" left="0" color="red" fontSize={12}>{errorMessage}</Text>
    </Flex>
  );
}
