import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  Select,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { postApi } from './fetchApi';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useApi } from './useApi';
import form from 'style/form.module.css';

export function Form({
  children,
  postUrl,
  successTitle,
  successDesc,
  onSuccess,
  submitButtonText,
}: {
  children: React.ReactNode;
  postUrl: string;
  successTitle: string;
  successDesc?: string;
  onSuccess?: (response: any) => void;
  submitButtonText: string;
}): JSX.Element {
  const methods = useForm();
  const toast = useToast();
  const { isSubmitting } = methods.formState;

  async function onSubmit(formValues: any): Promise<void> {
    const resp = await postApi(postUrl, formValues);

    if (resp.ok) {
      methods.reset();
      toast({
        title: successTitle,
        description: successDesc,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
      if (onSuccess) {
        const contentType = resp.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          onSuccess(formValues);
        } else {
          onSuccess(await resp.json());
        }
      }
    } else {
      let serverErrorMsg = 'Server error: see log for more info';

      if (resp.headers.get('content-type')?.includes('application/json')) {
        const serverError = await resp.json();
        serverErrorMsg = serverError.message;
      } else {
        const msg = await resp.text();

        if (msg && msg.length < 100) {
          serverErrorMsg = msg;
        }
      }

      methods.setError('serverError', {
        type: 'manual',
        message: serverErrorMsg,
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onChange={(): void => methods.clearErrors('serverError')}>
        {children}
        <FormControl isInvalid={methods.errors.serverError}>
          <FormErrorMessage>
            {methods.errors.serverError && methods.errors.serverError.message}
          </FormErrorMessage>
        </FormControl>
        <Box display="flex" justifyContent="flex-end">
          <Button
            mt={4}
            variant="primary"
            isLoading={isSubmitting}
            type="submit">
            {submitButtonText}
          </Button>
        </Box >
      </form>
    </FormProvider>
  );
}

export function FormInput({
  label,
  name,
  register,
  isRequired,
  placeholder,
  value,
}: {
  label: string;
  name: string;
  register?: null;
  isRequired?: boolean;
  placeholder?: string;
  value?: string;
}): JSX.Element {
  const formContext = useFormContext();

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired} isInvalid={formContext.errors[name]}>
        <FormLabel className={form.label} htmlFor={name}>
          {label}
        </FormLabel>
        <Input
          className={form.input}
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          defaultValue={value}
          ref={register ? formContext.register(register) : formContext.register}
        />
        <FormErrorMessage>
          {formContext.errors[name] && formContext.errors[name].message}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}

export function FormInputNumber({
  label,
  name,
  min = 1,
  step = 1,
  register,
  isRequired,
  value = 0,
}: {
  label: string;
  name: string;
  min?: number;
  step?: number;
  register?: null;
  isRequired?: boolean;
  value: number;
}): JSX.Element {
  const formContext = useFormContext();

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired} isInvalid={formContext.errors[name]}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Input
          autoComplete="off"
          name={name}
          type="number"
          min={min}
          defaultValue={value}
          step={step}
          ref={register ? formContext.register(register) : formContext.register}
        />
        <FormErrorMessage>
          {formContext.errors[name] && formContext.errors[name].message}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}

export function FormHidden({
  name,
  data,
  register,
}: {
  name: string;
  data: any;
  register?: null;
}): JSX.Element {
  const formContext = useFormContext();
  return (
    <FormControl isRequired={true} isInvalid={formContext.errors[name]}>
      <Input
        autoComplete="off"
        name={name}
        value={data}
        type="hidden"
        ref={register ? formContext.register(register) : formContext.register}
      />
    </FormControl>
  );
}

export function FormCustomMultiInput({
  name,
  label,
  placeholder,
  items,
  isRequired,
}: {
  name: string;
  label: string;
  placeholder: string;
  items: string[];
  isRequired: boolean;
}): JSX.Element {
  const { setValue } = useForm();
  const formContext = useFormContext();

  //const [json, setJson] = useState<string>(JSON.stringify(items));
  const [inputArray, setInputArray] = useState<string[]>(items);
  useEffect(() => {
    setValue(name, JSON.stringify(inputArray));
  }, [inputArray, setValue, name]);
  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Input
          autoComplete="off"
          value={JSON.stringify(inputArray)}
          name={name}
          type="hidden"
          ref={formContext.register}
        />
        {inputArray.map((item: string, i: number) => {
          return (
            <Input
              key={i}
              autoComplete="off"
              placeholder={placeholder}
              mb={2}
              type="text"
              value={inputArray[i]}
              onChange={(e) => {
                const ia = [...inputArray];
                if (ia[i] !== null) {
                  ia[i] = e.target.value;
                }
                setInputArray(ia);
              }}
            />
          );
        })}
        <Button
          marginRight={2}
          variant="primary"
          onClick={() => {
            const ia = [...inputArray];
            if (ia.length <= 1) return;
            ia.pop();
            setInputArray(ia);
          }}>
          -
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            const ia = [...inputArray];
            ia.push('');
            setInputArray(ia);
          }}>
          +
        </Button>
      </FormControl>
    </Box>
  );
}

export function FormCustomDropdown({
  name,
  label,
  placeholder,
  items,
  isRequired,
  selected,
}: {
  name: string;
  label: string;
  placeholder: string;
  items: Array<any>;
  isRequired: boolean;
  selected?: string;
}): JSX.Element {
  const formContext = useFormContext();
  const [value, setValue] = useState<string>(selected ?? '0');

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired}>
        <FormLabel htmlFor={name}>{label}</FormLabel>

        <Select
          placeholder={placeholder}
          name={name}
          ref={formContext.register}
          value={value}
          onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
            setValue(e.currentTarget.value);
          }}>
          {items.map((x: any) => (
            <option key={x.id} value={x.id}>
              {x.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function ArraySelect({
  name,
  label,
  items,
  isRequired,
  selected,
}: {
  name: string;
  label: string;
  items: string[];
  placeholder: string;
  selected?: number;
  isRequired: boolean;
}): JSX.Element {
  const formContext = useFormContext();
  const [value, setValue] = useState<number>(selected ?? 0);

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        {items && (
          <Select
            name={name}
            ref={formContext.register}
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              setValue(parseInt(e.currentTarget.value));
            }}>
            {items.map((item: string, i: number) => {
              return (
                <option key={i} value={i}>
                  {item}
                </option>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Box>
  );
}

export function FormSelect({
  name,
  label,
  placeholder,
  itemsUrl,
  isRequired,
  selected,
}: {
  name: string;
  label: string;
  itemsUrl: string;
  isRequired: boolean;
  placeholder: string;
  selected?: string;
}): JSX.Element {
  const itemsReq = useApi<[]>(itemsUrl);
  const formContext = useFormContext();
  const [value, setValue] = useState<string>(selected ?? '0');

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        {itemsReq.state !== 'error' && (
          <Select
            isDisabled={itemsReq.state !== 'success'}
            placeholder={
              itemsReq.state === 'loading' ? 'Laddar...' : placeholder
            }
            name={name}
            ref={formContext.register}
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              setValue(e.currentTarget.value);
            }}>
            {itemsReq.data?.map((x: any) => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
        )}
        {itemsReq.state === 'error' && <p>error</p>}
      </FormControl>
    </Box>
  );
}

export function FormTextarea({
  label,
  name,
  register,
  isRequired,
  placeholder,
  value,
}: {
  label: string;
  name: string;
  register?: null;
  isRequired?: boolean;
  placeholder?: string;
  value?: string;
}): JSX.Element {
  const formContext = useFormContext();

  return (
    <Box mb={4}>
      <FormControl isRequired={isRequired} isInvalid={formContext.errors[name]}>
        <FormLabel className={form.label} htmlFor={name}>
          {label}
        </FormLabel>
        <Textarea
          className={form.input}
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          defaultValue={value}
          ref={register ? formContext.register(register) : formContext.register}
        />
        <FormErrorMessage>
          {formContext.errors[name] && formContext.errors[name].message}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}
