import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Flex,
  Box,
  Image,
} from '@chakra-ui/react';
import firebase from 'firebase/app';

import loginStyle from 'style/login.module.css';
import formStyle from 'style/form.module.css';

import bg from 'static/images/login.jpg';
import logo from 'static/images/logo.svg';
import { colors } from 'lib/constants';

export function LoginPage(): JSX.Element {
  const [formState, setFormState] = React.useState<{
    state: string;
    data?: { message: string };
  }>({ state: 'initial' });
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  function login(e: React.SyntheticEvent): void {
    e.preventDefault();
    const email = emailRef.current?.value.trim();
    const password = passwordRef.current?.value.trim();

    if (!email || !password) return;

    setFormState({ state: 'loading' });

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setFormState({ state: 'failed_login', data: error });
        if (passwordRef.current) {
          passwordRef.current.value = '';
          passwordRef.current.focus();
        }
        console.log(error);
      });
  }

  return (
    <Flex justify="center" align="center" flex="1" h="100vh" pos="relative">
      <Box className={loginStyle.card}>
        <Box className={loginStyle.container}>
          <span className={loginStyle.welcomeContainer}>
            <Image loading="eager" src={logo} m="0 auto" w={150} />
            <h3
              style={{ color: colors.neutrals[300] }}
              className={loginStyle.subtitle}>
              Adminportal
            </h3>
          </span>
          <form onSubmit={login}>
            <FormControl isInvalid={formState.state === 'failed_login'}>
              <FormLabel className={formStyle.label} htmlFor="username">
                E-post
              </FormLabel>
              <div className={formStyle.inputContainer}>
                <input
                  className={formStyle.input}
                  placeholder="E-post"
                  ref={emailRef}
                  onChange={(): void => setFormState({ state: 'dirty' })}
                />
              </div>
              <br />
              <FormLabel className={formStyle.label} htmlFor="password">
                Lösenord
              </FormLabel>
              <div className={formStyle.inputContainer}>
                <input
                  className={formStyle.input}
                  placeholder="******"
                  type="password"
                  ref={passwordRef}
                  onChange={(): void => setFormState({ state: 'dirty' })}
                />
              </div>
              <FormErrorMessage>{formState.data?.message}</FormErrorMessage>
            </FormControl>
            <Button
              className={formStyle.button}
              w="100%"
              _hover={{ bg: colors.primary.hover }}
              bg={colors.primary[900]}
              borderRadius="8px"
              color="#fff"
              mt={4}
              isLoading={formState.state === 'loading'}
              type="submit"
              size="lg">
              Logga in
            </Button>
            <Box paddingTop={2} margin="0 auto" display="flex">
              <a className={formStyle.resetPassword} href="/">
                Glömt lösenord
              </a>
            </Box>
          </form>
        </Box>
      </Box>

      {/* bg image overlay */}
      <Box
        bg={colors.primary[900]}
        pos="absolute"
        w="100%"
        h="100%"
        top="0"
        left="0"
        zIndex="1"
        opacity="0.8"
      />

      {/* bg image */}
      <Image
        loading="eager"
        src={bg}
        w="100%"
        h="100%"
        pos="absolute"
        top="0"
        left="0"
        objectFit="cover"
      />
    </Flex>
  );
}
