import firebase from 'firebase/app';
import React from 'react';

export function useFirebaseUser(): firebase.User | null | undefined {
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>();

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(async function (user) {
      setCurrentUser(user);
    });
  }, []);

  return currentUser;
}
