import { Container } from 'components/Container';
import { DataTable } from 'components/DataTable/DataTable';
import { orderTable } from 'components/DataTable/DataTableModels';
import { PortalContext } from 'PortalContext';
import React from 'react';
import { useApi } from 'lib/useApi';
import { Order } from 'models/ApiModels';
import { ContainerHead } from 'components/ContainerHead';

export function OrdersPage(): JSX.Element {
  const { data: OrdersResp, state: respState } = useApi<Order[]>('/Orders');
  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <>
    <ContainerHead title="Ordrar" children=""/>
    <Container>
      <DataTable
        tableDef={orderTable}
        data={OrdersResp}
        searchableFields={['name', 'orderReference', 'unit.externalId']}
      />
    </Container>
    </>
  );
}
