import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import sidebar from 'style/sidebar.module.css';
type SidebarLinkProps = {
  href: string;
  title: string;
  icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export function SidebarLink(props: SidebarLinkProps): JSX.Element {
  const { href } = props;
  const { pathname } = useLocation();
  const history = useHistory();

  function onClick(): void {
    history.push(props.href);
    document.body.classList.remove('show-mobile-menu');
  }

  return (
    <div
      className={
        sidebar.button +
        `${pathname === href ? ` ${sidebar.buttonActive}` : ''}`
      }
      onClick={onClick}>
      <span style={{ display: 'inline-flex' }}>
        {props.icon ? (
          <props.icon
            style={{ marginRight: '8px' }}
            display="inline-flex"
            fill={`${pathname === href ? '#F27039' : 'white'}`}
          />
        ) : (
          ''
        )}
        {props.title}
      </span>
    </div>
  );
}
