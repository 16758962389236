import React from 'react'
import { useHistory } from 'react-router-dom'
import { Text, Link } from '@chakra-ui/react'
import { colors } from 'lib/constants'
import { ChevronLeftIcon } from '@chakra-ui/icons'

const BackButton = (): JSX.Element => {
    const history = useHistory()
    return (
        <Link onClick={() => history.goBack()} mb={5} variant="ghost" w="fit-content" _hover={{ color: colors.primary[900] }} alignItems="center">
            <Text fontSize={20} >
                <ChevronLeftIcon h={5} w={5} /> Tillbaka
            </Text>
        </Link>
    )
}

export default BackButton