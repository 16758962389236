import React from 'react';

export const customIcons = {
  person: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2223 10.3259C13.5645 10.7654 12.7911 11 12 11C10.9391 11 9.92172 10.5786 9.17157 9.82843C8.42143 9.07828 8 8.06087 8 7C8 6.20888 8.2346 5.43552 8.67412 4.77772C9.11365 4.11992 9.73836 3.60723 10.4693 3.30448C11.2002 3.00173 12.0044 2.92252 12.7804 3.07686C13.5563 3.2312 14.269 3.61216 14.8284 4.17157C15.3878 4.73098 15.7688 5.44372 15.9231 6.21964C16.0775 6.99556 15.9983 7.79983 15.6955 8.53074C15.3928 9.26164 14.8801 9.88635 14.2223 10.3259ZM13.1111 5.33706C12.7822 5.1173 12.3956 5 12 5C11.4696 5 10.9609 5.21072 10.5858 5.58579C10.2107 5.96086 10 6.46957 10 7C10 7.39556 10.1173 7.78224 10.3371 8.11114C10.5568 8.44004 10.8692 8.69639 11.2346 8.84776C11.6001 8.99914 12.0022 9.03874 12.3902 8.96157C12.7781 8.8844 13.1345 8.69392 13.4142 8.41422C13.6939 8.13451 13.8844 7.77814 13.9616 7.39018C14.0387 7.00222 13.9991 6.60009 13.8478 6.23463C13.6964 5.86918 13.44 5.55683 13.1111 5.33706ZM7.05025 15.0503C8.36301 13.7375 10.1435 13 12 13C13.8565 13 15.637 13.7375 16.9497 15.0503C18.2625 16.363 19 18.1435 19 20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21C17.7348 21 17.4804 20.8946 17.2929 20.7071C17.1054 20.5196 17 20.2652 17 20C17 18.6739 16.4732 17.4021 15.5355 16.4645C14.5979 15.5268 13.3261 15 12 15C10.6739 15 9.40215 15.5268 8.46447 16.4645C7.52678 17.4021 7 18.6739 7 20C7 20.2652 6.89464 20.5196 6.70711 20.7071C6.51957 20.8946 6.26522 21 6 21C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20C5 18.1435 5.7375 16.363 7.05025 15.0503Z"
      />
    ),
  },
  more: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19Z"
        fill="#333333"
      />
    ),
  },
  search: {
    path: (
      <path
        d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
        fill="#333333"
      />
    ),
  },
};
