import { Box, Button, Checkbox, Divider, Flex, Text } from '@chakra-ui/react';
import Pie from 'components/Donut';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useApi } from '../lib/useApi';
import { Facility, Product, Unit } from '../models/ApiModels';
import { NumberCard } from 'components/NumberCard';
import { unitsTable } from 'components/DataTable/DataTableModels';
import { Container } from 'components/Container';
import { createFacilityUnit, editFacility } from 'routes';
import { ContainerHead } from 'components/ContainerHead';
import { DataTable } from 'components/DataTable/DataTable';
import CreateDirectLink from 'components/CreateDirectLink';
import FacilityIncomeReport from 'components/FacilityIncomeReport';
import { Icon } from 'components/Icon';
import { ArrowDown, ArrowUp, FacilitiesIcon } from 'lib/IconLib';
import { AddIcon } from '@chakra-ui/icons';

export function FacilityPage(): JSX.Element {

  const [filterAvailableUnits, setFilterAvailableUnits] = useState<boolean>(false)
  const { id: facilityId } = useParams<{ id: string }>();
  const { data: facility } = useApi<Facility>(`/facilities/${facilityId}`);
  const { data: products } = useApi<Product[]>(
    `/products/${facility?.slug}/available`
  );
  const statsResp = useApi<{ [key: string]: number }>(
    `/statistics/facility/${facilityId}`
  );
  const { data: units } = useApi<Unit[]>(`/units/facility/${facilityId}`);

  const [showDirectLinkCreator, setShowDirectLinkCreator] = useState(false);
  let filteredUnits = units
  if (filterAvailableUnits) {
    filteredUnits = filteredUnits.filter(unit => unit.status === 'AVAILABLE' && new Date(unit.availableFrom) < new Date() ).sort((a, b) =>
      a.product.size - b.product.size)
  }

  return (
    <>
      <ContainerHead title={facility?.name}>
        <Box>
          <Pie
            data={{
              min: 0,
              max: 100,
              value: parseFloat((statsResp.data?.totalVacancy ? 100 - statsResp.data?.totalVacancy ?? 0 : 0).toFixed()),
              label: 'Total beläggning',
            }}
            width={210}
            height={210}
          />
        </Box>
        <NumberCard
          title="Totalt antal artiklar"
          number={statsResp.data?.totalUnits ?? 0}
        />
        <NumberCard
          title="Totalt antal lediga artiklar"
          number={statsResp.data?.totalAvailableUnits ?? 0}
        />
        <NumberCard
          title="Antal aktiva avtal"
          number={statsResp.data?.totalRentedUnits ?? 0}
        />
      </ContainerHead>
      <Container>
        <Flex direction="column">
          <Box pb="15px" className="ml__facility-actions">
            <Box
              mb={5}
              display="flex"
              flexDirection={{
                base: 'column',
                md: 'row',
              }}>
              <Link to={editFacility.path.replace(':id', facilityId)}>
                <Button variant="primary"><Icon icon={FacilitiesIcon} fill="white" /> <Text ml={3}>{editFacility.linkText}</Text></Button>
              </Link>
              <Link to={createFacilityUnit.path.replace(':id', facilityId)}>
                <Button variant="primary" ml={5}><AddIcon /> <Text ml={3}>{createFacilityUnit.linkText}</Text></Button>
              </Link>
            </Box>
          </Box>
          <Divider />
          <Box mt={3} mb={3}>
            {facility && <FacilityIncomeReport facility={facility} />}
          </Box>

          <Divider />
          <Box pt={8} pb={8} d="flex" alignItems="center">
            <Button
              variant="primary"
              onClick={(): void =>
                setShowDirectLinkCreator(!showDirectLinkCreator)
              }>
              <Text mr={3}>
                Skapa direktlank
              </Text>
              <Icon icon={showDirectLinkCreator ? ArrowUp : ArrowDown} fill="white" />
            </Button>
            {showDirectLinkCreator && (
              <>
                <CreateDirectLink
                  facility={facility?.id ?? 0}
                  products={products ?? []}
                />
              </>
            )}
            <Box>
              <Checkbox ml={10} colorScheme='orange' checked={filterAvailableUnits} onChange={() => setFilterAvailableUnits(!filterAvailableUnits)}>Filtrera lediga förråd</Checkbox>
            </Box>
          </Box>
          <Box>
            <DataTable
              tableDef={unitsTable}
              data={filteredUnits}
              searchableFields={[
                'status',
                'objectID',
                'externalId',
                'product.size',
              ]}
            />
          </Box>
        </Flex>
      </Container>
    </>
  );
}
