import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export function Container({
  children,
  props,
}: {
  children: React.ReactNode;
  props?: BoxProps;
}): JSX.Element {
  return (
    <Box className="content" {...props}>
      {children}
    </Box>
  );
}
