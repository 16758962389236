import {
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Box
} from '@chakra-ui/react';
import { fetchApi } from 'lib/fetchApi';
import { Facility } from 'models/ApiModels';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import DatePicker, { MONTHS_SHORT } from './DatePicker';

type Report = {
  totalAmount: number;
  totalAmountNoVAT: number;
  totalOrganizationAmount: number;
  totalOrganizationVAT: number;
  totalPersonAmount: number;
  paymentCount: number;
  activeOrders: number;
};

export default function FacilityIncomeReport({
  facility,
}: {
  facility: Facility;
}): JSX.Element {
  const toDate = new Date();
  toDate.setDate(0)
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1)
  fromDate.setDate(1)
  const [selectedDate, setSelectedDate] = useState<DateRange | undefined>({
    from: fromDate,
    to: toDate,
  });

  const [report, setReport] = useState<Report | null>(null);

  useEffect(() => {
    async function fetchReport() {
      if (
        selectedDate === undefined ||
        selectedDate.from === null ||
        selectedDate.to === null ||
        typeof selectedDate.to === 'undefined' ||
        typeof selectedDate.from === 'undefined'
      ) {
        return false;
      }
      const res = await fetchApi(
        `/facilities/incomereport/${facility.id
        }?from=${selectedDate.from.toJSON()}&to=${selectedDate.to.toJSON()}`
      );
      if (res.ok) {
        const json = (await res.json()) as Report;
        setReport(json);
      }
    }
    fetchReport();
  }, [selectedDate, facility.id]);

  function getDateString(from: Date, to: Date): string {
    const fromMonth = MONTHS_SHORT[from.getMonth()];
    const toMonth = MONTHS_SHORT[to.getMonth()];
    return `${from.getDate()} ${fromMonth} - ${to.getDate()} ${toMonth}`;
  }

  return (
    <>
      <Text fontSize="2xl" fontWeight={600} mb={3}>
        Inkomstrapport
      </Text>
      <DatePicker
        dayPickerProps={{ mode: "range", onSelect: setSelectedDate, selected: selectedDate, defaultMonth: fromDate }}  >
        {selectedDate?.from?.toLocaleDateString()} :{" "}
        {selectedDate?.to?.toLocaleDateString()}
      </DatePicker>
      <Box display="flex" mt={5} fontSize="xl">
        <Text fontWeight={600} mr={1}>Vald period: </Text><Text fontWeight={400}>{selectedDate && getDateString(
          selectedDate.from ?? fromDate,
          selectedDate.to ?? toDate
        )}</Text>
      </Box>
      <SimpleGrid
        pt={4}
        spacing="1em"
        margin={{ base: '0 auto', md: 0 }}
        columns={{ base: 1, sm: 2, lg: 3, xl: 5 }}>
        <Stat>
          <StatLabel>Total inkomst</StatLabel>
          <StatNumber>{report?.totalAmount}:-</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total inkomst (utan moms)</StatLabel>
          <StatNumber>{report?.totalAmountNoVAT}:-</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total inkomst (privatperson)</StatLabel>
          <StatNumber>{report?.totalPersonAmount}:-</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total inkomst (företag)</StatLabel>
          <StatNumber>
            {(report?.totalOrganizationAmount ?? 0) -
              (report?.totalOrganizationVAT ?? 0)}
            :-
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total moms</StatLabel>
          <StatNumber>{report?.totalOrganizationVAT}:-</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Antal inbetalningar</StatLabel>
          <StatNumber>{report?.paymentCount}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Antal ordrar under perioden</StatLabel>
          <StatNumber>{report?.activeOrders}</StatNumber>
        </Stat>
      </SimpleGrid>
    </>
  );
}
