import { useState, useEffect } from 'react';

const getWidth = () => {
  if (typeof window !== undefined || typeof document !== undefined) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
};

const _mobileWidth = 1024;

function useCurrentWidth(): [isMobile: boolean, isTouch: boolean] {
  const [isMobile, setIsMobile] = useState(getWidth() < _mobileWidth);

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsMobile(getWidth() < _mobileWidth), 200);
    };
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const isTouch = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };

  return [isMobile, isTouch()];
}

export default useCurrentWidth;
