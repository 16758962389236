import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { colors } from 'lib/constants';
import React, { FormEvent } from 'react';

export function TableTop({
  children,
  title,
}: {
  children?: React.ReactNode;
  title?: string;
}): JSX.Element {
  return (
    <Box marginBottom={4}>
      {title && (
        <Text fontSize="xl" fontWeight={600}>
          {title}
        </Text>
      )}
      {children}
    </Box>
  );
}

export function TableSearchBox({
  currentRefinement,
  refine,
  placeHolder
}: {
  currentRefinement: string;
  refine: (value: string) => void;
  placeHolder: string;
}): JSX.Element {
  return (
    <Flex pr={4}>
      <InputGroup>
        <Input
          type="search"
          placeholder={placeHolder || 'Sök'}
          value={currentRefinement}
          onChange={(event: FormEvent<HTMLInputElement>): void =>
            refine(event.currentTarget.value)
          }
          w={470}
        />
        <InputRightElement
          className="search-input-icon"
          borderLeft={`1px solid ${colors.secondary.lightGrey}`}
          children={<SearchIcon color={colors.neutrals[900]} />}
        />
      </InputGroup>
    </Flex>
  );
}
