import { Container } from 'components/Container';
import { placesTable } from 'components/DataTable/DataTableModels';
import { IconCard } from 'components/IconCard';
import React from 'react';
import { routes } from '../../routes';
import userIcon from 'static/images/user_icon.svg';
import { DataTable } from 'components/DataTable/DataTable';
import { useApi } from 'lib/useApi';
import { Place } from 'models/ApiModels';
import { ContainerHead } from 'components/ContainerHead';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

export function PlacesPage(): JSX.Element {
  const { data: places } = useApi<Place[]>(`/places`);

  return (
    <>
      <ContainerHead title="Platser" children="" />
      <Container>
        <Link to={routes.createPlace.path}>
          <Button variant="primary" mb={10}><AddIcon mr={3} />{routes.createPlace.linkText}</Button>
        </Link>
        <DataTable
          title="Platser"
          tableDef={placesTable}
          data={places}
          showSearch={true}
          searchableFields={['name']}
        />
      </Container>
    </>
  );
}
