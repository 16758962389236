import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import Donut from 'style/donut.module.css';

const Pie = (props: {
  data: { min: number; max: number; value: number; label: string };
  width: number;
  height: number;
}): JSX.Element => {
  const ref = useRef(null);

  const fullAngle = 100;
  const innerRadius = (props.width / 2 / 5) * 4.5;
  const outerRadius = props.width / 2;
  const top = props.height / 2;
  const left = props.width / 2;

  const createArc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .cornerRadius(10);

  useEffect(() => {
    //const data = createPie(props.data);
    const group = d3.select(ref.current);

    const scale = d3
      .scaleLinear()
      .domain([props.data.min, props.data.max])
      .range([0, fullAngle]);

    group.selectAll('g > *').remove();
    const arcback = createArc;
    arcback.startAngle(0);
    arcback.endAngle(fullAngle);
    group
      .append('path')
      .attr('class', 'arcPathBg')
      .style('fill', '#DADEE6')
      .datum(scale(props.data.max))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .attr('d', arcback as any);

    const arcBody = createArc;
    arcBody.startAngle(0);
    arcBody.endAngle((props.data.value / 100) * 2 * Math.PI);
    group
      .append('path')
      .attr('class', 'arcPath')
      .style('fill', '#80AF5A')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .attr('d', arcBody as any);

    const text = group
      .append('g')
      .attr('class', Donut.text)
      .append('text')
      .attr('class', 'text')
      .attr('text-anchor', 'middle');

    const label = text
      .append('tspan')
      .attr('class', Donut.unitText)
      .attr('x', 0)
      .attr('dy', -25);
    const valueT = text
      .append('tspan')
      .attr('class', Donut.valueText)
      .attr('x', 0)
      .attr('dy', 50);

    valueT.text(props.data.value.toString() + '%');
    label.text(props.data.label);
  }, [props.data, createArc]);

  return (
    <svg width={props.width} height={props.height}>
      <g ref={ref} transform={`translate(${left} ${top})`} />{' '}
    </svg>
  );
};

export default Pie;
