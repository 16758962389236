import { Container } from 'components/Container';
import { DataTable } from 'components/DataTable/DataTable';
import { pastDueTable } from 'components/DataTable/DataTableModels';
import { PortalContext } from 'PortalContext';
import React from 'react';
import { useApi } from '../lib/useApi';
import { PastDue } from 'models/ApiModels';
import { ContainerHead } from 'components/ContainerHead';

export function DuePaymentsPage(): JSX.Element {
  const { data: dueResp, state: respState } = useApi<PastDue[]>(
    '/payments/order/pastdue'
  );

  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <>
    <ContainerHead children="" title="Sena betalningar"/>
    <Container>
      <DataTable
        tableDef={pastDueTable}
        data={dueResp}
        searchableFields={['orderEmail', 'unitExternalId']}
      />
    </Container>
    </>
  );
}
