import { Flex, Heading, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import React from 'react';
import BackButton from './BackButton';
interface Props extends SimpleGridProps {
  children: React.ReactNode;
  title?: string;
  backButton?: boolean;
}
export function ContainerHead({
  children,
  title,
  columns,
  backButton = true
}: Props): JSX.Element {
  return (
    <Flex bg="#F3F3F3" direction="column" p={10}>
      {backButton && <BackButton />}
      <Flex>
        {title && (
          <Heading as="h1" fontSize="36px" mb={5}>
            {title}
          </Heading>
        )}
      </Flex>
      <SimpleGrid
        spacing={{ base: "0.5em", md: "1em" }}
        margin={{ base: '0 auto', md: 0 }}
        columns={columns ?? { base: 1, sm: 2, lg: 3, xl: 5 }}>
        {children}
      </SimpleGrid>
    </Flex >
  );
}
