import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { Container } from 'components/Container';
import { Form } from 'lib/form';
import { EditPageProps } from 'routes';
import { useHistory, useParams } from 'react-router-dom';
import { useApi } from 'lib/useApi';
import BackButton from 'components/BackButton';

export function EditPage({
  title,
  fields,
  postUrl,
  successTitle,
  redirectUrl,
  getUrl,
}: EditPageProps): JSX.Element {
  const params = useParams<{ id: string }>();
  const { data: itemData, state: itemGetStatus, setData, error } = useApi(
    getUrl.replace(':id', params.id)
  );
  const history = useHistory();
  const _fields = React.useMemo(() => {
    if (!isObject(itemData)) return [];

    return fields(itemData).map((f) =>
      React.cloneElement(f, {
        value: itemData[f.props.name],
        key: f.props.name,
      })
    );
  }, [fields, itemData]);

  if (itemGetStatus === 'loading')
    return (
      <Container>
        <Spinner />
      </Container>
    );
  if (itemGetStatus === 'error' || !itemData)
    return (
      <Container>
        <Text>{error?.toString()}</Text>
      </Container>
    );

  return (
    <>
      <Container>
        <BackButton />
        <Text fontSize="4xl" mb={10} mt={5}>
          {title}
        </Text>
        <Box maxW={500}>
          <Form
            postUrl={postUrl}
            successTitle={successTitle}
            onSuccess={(e) => {
              setData(e);
              if (redirectUrl) {
                history.push(redirectUrl.replace(':id', params.id));
              }
            }}
            submitButtonText="Spara">
            {_fields}
          </Form>
        </Box>
      </Container>
    </>
  );
}

const isObject = (arg: unknown): arg is Record<string, string> =>
  typeof arg === 'object' && arg !== null;
