/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { StatusLabel } from 'components/StatusLabel';
import { Link } from 'react-router-dom';
import { PastDue, ProductTypes } from 'models/ApiModels';
import { Button, Tag } from '@chakra-ui/react';
import { Icon } from 'components/Icon';
import { Logout } from 'lib/IconLib';
import { formatUnitNum } from 'lib/formatUnitNum';

const idColWidht = '80px';
const idColWidhtExtra = '90px';
const actionDropDownColWidth = '80px';

const linkStyles = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }

export interface TableDefinition {
  header: string;
  columns: TableColumnDefinition[];
  overrideViewRowUrl?: string;
  noLinks?: boolean;
  applyRowStyle?(
    rowItem: RowItem | undefined | null
  ): React.CSSProperties | undefined;
  rowStyles?: Record<string, string>;
}

interface TableColumnDefinition {
  header: string;
  accessor: string;
  tdStyles?: Record<string, string>;
  customHtml?: (rowItem: RowItem) => JSX.Element;
}

interface RowItem {
  [name: string]: any;
}

export const facilitiesTable: TableDefinition = {
  header: 'Anläggningar',
  overrideViewRowUrl: '/anlaggningar',
  columns: [
    {
      header: 'Namn',
      accessor: 'name',
    },
    {
      header: 'Artiklar',
      accessor: 'unitCount',
    },
    {
      header: 'Beläggning',
      accessor: 'Beläggning',
      customHtml: (rowItem) => (
        <Link to={`/anlaggningar/${rowItem?.id}`}>{rowItem ? (100 - rowItem?.vacancy).toFixed(1) : "-"} %</Link>
      )
    },
  ],
};

//externalId: "40002-01-0184"
//orderAmount: "1 050,00"
//orderAmountVAT: "1 050,00"
//orderArticle: "007"
//orderEmail: "ensommarblomma@gmail.com"
//orderType: "Hyra F�rr�d"
//status: "G"
//transferred: 1
export const transfersTable: TableDefinition = {
  header: 'Klarnaflytt',
  noLinks: true,
  columns: [
    {
      header: 'Artikelnummer',
      accessor: 'externalId',
      tdStyles: {
        width: idColWidht,
      },
    },
    {
      header: 'Kostnad',
      accessor: 'orderAmount',
    },
    { header: 'E-post', accessor: 'orderEmail' },
    {
      header: 'Flyttad',
      accessor: 'transferred',
      customHtml: (rowItem) => {
        return (
          <Tag
            px="1em"
            py={1}
            variant="outline"
            colorScheme={rowItem?.transferred === 2 ? 'green' : 'red'}>
            {rowItem?.transferred === 1 && 'Nej'}
            {rowItem?.transferred === 2 && 'Ja'}
          </Tag>
        );
      },
    },
    {
      header: '',
      accessor: 'reminder',
      customHtml: (rowItem) => {
        return (
          <>
            {rowItem?.transferred !== 2 && rowItem?.orderEmail && (
              <Button>Påminn</Button>
            )}
          </>
        );
      },
    },
  ],
};

export const unitsTable: TableDefinition = {
  header: 'Artiklar',
  overrideViewRowUrl: '/objekt',
  columns: [
    {
      header: 'Artikelnummer',
      accessor: 'externalId',
      tdStyles: {
        width: idColWidhtExtra,
      },
    },
    {
      header: 'Förrådsnummer',
      accessor: 'externalId+1',
      tdStyles: {
        width: idColWidht,
      },
      customHtml: (rowItem) => (
        <span>{formatUnitNum(rowItem.externalId)}</span>
      ),
    },
    {
      header: 'Storlek',
      accessor: 'product.size',
      customHtml: (rowItem) => (
        <span>
          <div>
            {rowItem.product.size} m<sup>2</sup>
            {rowItem.product.name.includes('m3') && <div>{`${rowItem.product.name.split(' ')[0]}`} m<sup>3</sup></div>}
          </div>
        </span>
      ),
    },
    {
      header: 'Anläggningsnummer',
      accessor: 'facilityId',
      tdStyles: {
        width: idColWidht,
      },
    },
    {
      header: 'Status',
      accessor: 'status',
      customHtml: (rowItem) => {
        let status = '';
        if(rowItem?.availableFrom){
          const date = new Date(rowItem?.availableFrom + 'Z');
          const todaysDate = new Date();
          if(date > todaysDate){
            status = 'BOOKED'
          }
          else{
            status = rowItem?.status
          }
        }
          return (
            <StatusLabel status={status} />
            );
      },
    },
    {
      header: 'Ledig från',
      accessor: 'availableFrom',
      customHtml: (rowItem) => {
        let time = '';
        if (rowItem?.availableFrom) {
          const date = new Date(rowItem?.availableFrom + 'Z');
          if (date.getTime() > new Date().getTime()) {
            time = date.toDateString();
          }
        }
        return <>{time}</>;
      },
    },
  ],
};

export const placesTable: TableDefinition = {
  header: 'Platser',
  columns: [
    {
      header: 'Namn',
      accessor: 'name',
    },
    {
      header: '',
      accessor: 'actions',
      tdStyles: {
        width: actionDropDownColWidth,
      },
    },
  ],
};

export const usersTable: TableDefinition = {
  header: 'Konton',
  columns: [
    {
      header: 'Epost',
      accessor: 'email',
    },
  ],
};

export const orderTable: TableDefinition = {
  header: 'Ordrar',
  overrideViewRowUrl: '/ordrar',
  applyRowStyle: (rowItem) => {
    if (rowItem?.status !== 1) {
      return { opacity: 0.5 };
    }
    return undefined;
  },
  columns: [
    { header: 'Referens', accessor: 'orderReference' },
    {
      header: 'Anläggning',
      accessor: 'unit.facility.name',
      customHtml: (rowItem): JSX.Element => (
        <Link
          style={linkStyles}
          to={`/anlaggningar/${rowItem.unit.facility.id}`}>
          {rowItem.unit.facility.name} <Icon fill="#333" icon={Logout} />
        </Link>
      ),
    },
    {
      header: 'Artikelnummer',
      accessor: 'unit.externalId',
      tdStyles: {
        width: idColWidht,
      },
    },
    {
      header: 'Förrådsnummer',
      accessor: 'unit.id',
      customHtml: (rowItem): JSX.Element => (
        <Link
          style={linkStyles}
          to={`/objekt/${rowItem.unit.id}`}>
          {formatUnitNum(rowItem.unit.externalId)}{' '}
          <Icon fill="#333" icon={Logout} />
        </Link>
      ),
    },
    {
      header: 'Storlek',
      accessor: 'unit.product.size',
      customHtml: (rowItem): JSX.Element => (
        <Link
          style={linkStyles}
          to={`/produkter/${rowItem.unit.product.id}`}>
          <div>
            <div>{rowItem.unit.product.size} m<sup>2</sup>
            </div>
            {rowItem.unit.product.name.includes('m3') && <div>{`${rowItem.unit.product.name.split(' ')[0]}`} m<sup>3</sup></div>}
          </div>
          <Icon fill="#333" icon={Logout} />
        </Link>
      )
    },
    {
      header: 'Status',
      accessor: 'status',
      customHtml: (rowItem): JSX.Element => {
        return (
          <span>
            <StatusLabel status={rowItem.status}></StatusLabel>
          </span>
        );
      },
    },
    {
      header: 'Företag',
      accessor: 'calculateSalesTax',
      customHtml: (rowItem): JSX.Element => {
        return (
          <span>{rowItem?.calculateSalesTax ?? false ? 'Ja' : 'Nej'}</span>
        );
      },
    },
    {
      header: 'Land',
      accessor: 'country',
    },
    {
      header: 'Aktivt sedan',
      accessor: 'createdAt',
      customHtml: (rowItem): JSX.Element => {
        const date = new Date(rowItem?.createdAt + 'Z' ?? null);
        return (
          <Link to={`/ordrar/${rowItem?.id}`}>
            {date.toLocaleString('sv-SE', {
              dateStyle: 'short',
              timeStyle: 'short',
            })}
          </Link>
        );
      },
    },
  ],
};

export const ProductColumns: TableDefinition = {
  header: 'Produkter',
  columns: [
    {
      header: 'Namn',
      accessor: 'name',
    },
    {
      header: 'Beskrivning',
      accessor: 'description',
    },
    {
      header: 'Typ',
      accessor: 'type',
      customHtml: (rowItem): JSX.Element => {
        return <span>{ProductTypes[rowItem?.type ?? 0]}</span>;
      },
    },
    {
      header: 'Storlek',
      accessor: 'size',
      customHtml: (rowItem): JSX.Element => {
        return (
          <span>
            <div>
              {rowItem?.size ?? 0} m
              <sup>
                2
              </sup >
            </div>
          </span>
        );
      },
    },
  ],
};

export const CampaignColumns: TableDefinition = {
  header: 'Kampanj',
  overrideViewRowUrl: `/kampanjer/edit`,
  columns: [
    {
      header: 'Namn',
      accessor: 'name',
    },
  ],
};

export const pastDueTable: TableDefinition = {
  header: 'Ogenomförda betalningar',
  noLinks: true,
  columns: [
    { header: 'Förråd', accessor: 'unitExternalId' },
    {
      header: 'Email',
      accessor: 'orderEmail',
    },
    { header: 'Order', accessor: 'order.orderReference' },
    {
      header: 'Betaldatum',
      accessor: 'paymentDueAt',
      customHtml: (rowItem): JSX.Element => {
        rowItem = rowItem as PastDue;
        const date = new Date(rowItem.paymentDueAt ?? null);
        return <span>{date.toLocaleDateString('sv-SE')}</span>;
      },
    },
    {
      header: 'Orderstatus',
      accessor: '',
      customHtml: (rowItem): JSX.Element => {
        rowItem = rowItem as PastDue;
        return (
          <span>{rowItem?.order.status === 0 ? 'Avslutad' : 'Aktiv'}</span>
        );
      },
    },
    {
      header: 'Påminnelse skickad',
      accessor: 'paymentDueAt',
      customHtml: (rowItem): JSX.Element => {
        rowItem = rowItem as PastDue;
        const date = new Date(rowItem?.order?.paymentNotificationAt ?? null);
        return <span>{date.toLocaleDateString('sv-SE')}</span>;
      },
    },
    {
      header: 'Gå till',
      accessor: '',
      customHtml: (rowItem): JSX.Element => {
        return (
          <Link to={`/ordrar/${rowItem?.order.id}`}>
            <Button>Gå till order</Button>
          </Link>
        );
      },
    },
    {
      header: 'Kontakta support',
      accessor: '',
      customHtml: (rowItem): JSX.Element => {
        rowItem = rowItem as PastDue;
        return <span>{rowItem?.closerLook ? 'Ja' : 'Nej'}</span>;
      },
    },
  ],
};

//Todo: Loglevel with table design??
export const eventTable: TableDefinition = {
  header: 'Händelser',
  overrideViewRowUrl: '/events',
  columns: [
    {
      header: 'Tidpunkt',
      accessor: 'createdAt',
      customHtml: (rowItem): JSX.Element => {
        const date = new Date(rowItem?.createdAt ?? null);
        return <span>{date.toLocaleString('sv-SE')}</span>;
      },
    },
    {
      header: 'Av',
      accessor: 'createdByDisplayName',
      customHtml: (rowItem): JSX.Element => {
        const name = rowItem?.createdByDisplayName;
        return <span>{name ?? 'Systemet'}</span>;
      },
    },
    {
      header: 'Relaterad entitet',
      accessor: 'entityType',
      customHtml: (rowItem): JSX.Element => {
        const entity = rowItem?.entityType?.substr(10);
        return <span>{entity}</span>;
      },
    },
    {
      header: 'Funktion',
      accessor: 'action',
      customHtml: (rowItem): JSX.Element => {
        return (
          <span>
            <StatusLabel status={rowItem?.action} />
          </span>
        );
      },
    },
  ],
};

export const notificationsTable: TableDefinition = {
  header: 'Notifieringar',
  overrideViewRowUrl: '/events',
  columns: [
    {
      header: 'Tidpunkt',
      accessor: 'createdAt',
      customHtml: (rowItem): JSX.Element => {
        const date = new Date(rowItem?.createdAt + 'Z' ?? null);
        return <span>{date.toLocaleString('sv-SE')}</span>;
      },
    },
    {
      header: 'Relaterad entitet',
      accessor: 'entityType',
      customHtml: (rowItem): JSX.Element => {
        const entity = rowItem?.entityType.substr(10);
        return <span>{entity}</span>;
      },
    },
    {
      header: 'Meddelande',
      accessor: 'action',
      customHtml: (rowItem): JSX.Element => {
        return <span>{rowItem?.description}</span>;
      },
    },
  ],
};
