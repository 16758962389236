import { Container } from 'components/Container';
import { DataTable } from 'components/DataTable/DataTable';
import { facilitiesTable } from 'components/DataTable/DataTableModels';
import { PortalContext } from 'PortalContext';
import React from 'react';
import { useApi } from '../lib/useApi';
import { routes } from '../routes';
import { Facility } from 'models/ApiModels';
import FacilitiesIncome from 'components/FacilitiesIncome';
import { Box, Button } from '@chakra-ui/react';
import { ContainerHead } from 'components/ContainerHead';
import { AddIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export function FacilitiesPage(): JSX.Element {
  const { data: facilitiesResp, state: respState } = useApi<Facility[]>(
    '/facilities'
  );

  const { dispatch } = React.useContext(PortalContext);

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  return (
    <>
      <ContainerHead children="" title='Anläggningar' />
      <Container>
        <Link to={routes.createFacility.path}>
          <Button variant="primary" mb={10}><AddIcon mr={3} />Ny anläggning</Button>
        </Link>
        <DataTable
          title="Anläggningar"
          tableDef={facilitiesTable}
          data={facilitiesResp}
          searchableFields={['name']}
        />
        <Box py={8}>
          <FacilitiesIncome />
        </Box>
      </Container>
    </>
  );
}
