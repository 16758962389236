import {
  Text,
  Table,
  TableCaption,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { fetchApi } from 'lib/fetchApi';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import DatePicker, { MONTHS_SHORT } from './DatePicker';

type Report = {
  totalAmount: number;
  totalAmountNoVAT: number;
  totalOrganizationAmount: number;
  totalOrganizationVAT: number;
  totalPersonAmount: number;
  paymentCount: number;
  externalId: string;
  activeOrders: number;
};

export default function FacilitiesIncome(): JSX.Element {
  const today = new Date(new Date().setHours(23, 59, 0, 0));
  const [selectedDate, setSelectedDate] = useState<DateRange | undefined>({
    from: today,
    to: today,
  });

  const [report, setReport] = useState<Report[] | null>(null);

  useEffect(() => {
    async function fetchReport() {
      if (
        selectedDate === undefined ||
        selectedDate.from === null ||
        selectedDate.to === null ||
        typeof selectedDate.to === 'undefined' ||
        typeof selectedDate.from === 'undefined'
      ) {
        return false;
      }
      const res = await fetchApi(
        `/facilities/incomereport?from=${selectedDate?.from.toJSON()}&to=${selectedDate.to.toJSON()}`
      );
      if (res.ok) {
        const json = (await res.json()) as Report[];
        setReport(json);
      }
    }
    fetchReport();
  }, [selectedDate]);

  function getDateString(from: Date, to: Date): string {
    const fromMonth = MONTHS_SHORT[from.getMonth()];
    const toMonth = MONTHS_SHORT[to.getMonth()];
    return `${from.getDate()} ${fromMonth} - ${to.getDate()} ${toMonth}`;
  }

  function getTotal(noVat: boolean) {
    return report?.reduce((a, obj) => {
      if (noVat) {
        return a + obj.totalAmountNoVAT;
      } else {
        return a + obj.totalAmount;
      }
    }, 0);
  }

  return (
    <>
      <Text fontSize="xl" fontWeight={600} mb={3}>
        Inkomstrapport - Alla anläggningar
      </Text>
      <Text>Period</Text>
      <DatePicker
        dayPickerProps={{ mode: "range", selected: selectedDate, onSelect: setSelectedDate }}>
        {selectedDate?.from?.toLocaleDateString()}-
        {selectedDate?.to?.toLocaleDateString()}
      </DatePicker>
      <Table variant="simple">
        <TableCaption placement="top">
          Inkomst per anläggning under perioden{' '}
          {selectedDate && getDateString(selectedDate.from ?? today, selectedDate.to ?? today)}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Anläggning</Th>
            <Th isNumeric>Total inkomst (inkl moms)</Th>
            <Th isNumeric>Total inkomst (privatpersoner, momsfritt)</Th>
            <Th isNumeric>Total inkomst (företag, momspliktigt)</Th>
            <Th isNumeric>Total moms</Th>
          </Tr>
        </Thead>
        <Tbody>
          {report &&
            report.map((report: Report) => {
              return (
                <Tr key={report.externalId}>
                  <Td>{report.externalId}</Td>
                  <Td isNumeric>{report.totalAmount}</Td>
                  <Td isNumeric>{report.totalPersonAmount}</Td>
                  <Td isNumeric>
                    {report.totalOrganizationAmount -
                      report.totalOrganizationVAT}
                  </Td>
                  <Td isNumeric>{report.totalOrganizationVAT}</Td>
                </Tr>
              );
            })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Totalt</Th>
            <Th isNumeric>{report && getTotal(false)}:-</Th>
            <Th isNumeric>
              {report &&
                report.reduce((a, b) => {
                  return a + b.totalPersonAmount;
                }, 0)}
              :-
            </Th>
            <Th isNumeric>
              {report &&
                report.reduce((a, b) => {
                  return (
                    a + (b.totalOrganizationAmount - b.totalOrganizationVAT)
                  );
                }, 0)}
              :-
            </Th>
            <Th isNumeric>
              {report &&
                report.reduce((a, b) => {
                  return a + b.totalOrganizationVAT;
                }, 0)}
              :-
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </>
  );
}
