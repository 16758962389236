export interface Discount {
  name: string;
  discountType: 'fixed' | 'percent';
  isActive: boolean;
  requireFirstOrder: boolean;
  requireUnique: boolean;
  includeFirstMonth: boolean
  discountedMonths: number;
  appliesToProductId: number[];
  appliesToAll: boolean;
  percentOff?: number
  amountOff?: number;
  id?: number;
}

export interface Campaign {
  activeFrom: string;
  activeTo: string;
  appliesToAll: boolean;
  description: string;
  appliesToFacilityId: number[];
  discounts: Discount[];
  isActive;
  id: number;
  name: string;
}

export interface Facility {
  id: number;
  name: string;
  units: Unit[] | null;
  address: string;
  location: Place;
  nearbyPlaces: Place[];
  availableUnits: number;
  unitCount: number;
  slug: string;
  vakaIP: string;
  externalId: string;
}

export type UnitComment = {
  id?: number | undefined;
  orderId: number;
  text: string;
  createdAt: Date;
  createdByUserId: string;
};

export type Transfer = {
  externalId: string;
  orderAmount: string;
  orderAmountVAT: string;
  orderArticle: string;
  orderEmail: string;
  orderType: string;
  status: string;
  transferred: Transferred;
};
export type Transfers = Transfer[];
export enum Transferred {
  UNKNOWN,
  NO,
  YES,
}

export type Prediction = { lastPaymentAt: string; lastEntryDate: string };

export const ProductTypes = ['', 'Förråd', 'Parkering', 'Kontor'];
export type CreateProduct = {
  name: string;
  description: string;
  price: number;
  size: number;
  type: number;
  id?: number;
};
export interface Product {
  name: string;
  size: number;
  description: string;
  lowestPrice: number;
  products: Product[];
  price: number;
  type: number;
  id: number;
  objectID: string;
}

export interface Place {
  description: string;
  id: number;
  title: string;
}

export interface Order {
  userId: string;
  unitId: number;
  unit: Unit;
  createdAt: Date;
  activeTo: Date | null;
  status: number;
  paymentProvider: string;
  paymentToken: string;
  payments: Payment[];
  orderReference: string;
  monthlyAmount: number;
  failedAttempts: number;
  id: number;
  objectID: string;
  calculateSalesTax: boolean;
}
export interface PastDue {
  paymentDueAt: Date;
  order: Order;
  payment: Payment;
  orderEmail: string;
  unitExternalId: string;
  closerLook: boolean;
}
export interface Payment {
  id: number;
  capturedAt: Date | null;
  amount: number;
  taxAmount: number;
  totalAmount: number;
  dueDate: Date;
  isDue: boolean;
}

export interface Unit {
  externalId: string;
  status: string;
  productId: number;
  product: Product;
  facility: Facility;
  facilityId: number;
  id: number;
  objectID: string;
  size: number;
  availableFrom: string;
  comments: UnitComment[];
}

export interface User {
  id: number;
  name: string;
  email: string;
  roles: { [key: string]: boolean };
}

export const UnitStatus = {
  available: { id: 'AVAILABLE', label: 'Ledig' },
  booked: { id: 'CHECKED_OUT', label: 'Bokad' },
  checkedOut: { id: 'BOOKED', label: 'Utcheckad' },
  locked: { id: 'LOCKED', label: 'Låst' },
  incit: { id: 'INCIT', label: 'Incit' },
  notRentable: { id: 'NOT_RENTABLE', label: 'Ej uthyrbart' },
};

export class UserRoles {
  public static PortalAdmin = 'portal:admin';
  public static SecuritasAdmin = 'portal:securitas';
}

export interface EventLog {
  id: number;
  description: string;
  logLevel: 2;
  facility?: Facility;
  user?: User;
  unit?: Unit;
}

export interface Capture {
  captureId: string;
  reference: string;
  klarnaReference: string;
  capturedAmount: number;
  capturedAt: Date;
  description: string;
  refundedAmount: number;
  billingAddress: Address;
}

export interface Address {
  given_name: string;
  family_name: string;
  title: string;
  street_address: string;
  street_address2: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
  email: string;
  phone: string;
}

export interface Event {
  id: number;
  logLevel: number;
  createdAt: number;
  createdByDisplayName: number;
  entityType: string;
  description: string;
  action: string;
}
