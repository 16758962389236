import { Tag } from '@chakra-ui/react';
import { colors } from 'lib/constants';
import React from 'react';

interface StatusLabelProps {
  status:
  | 0
  | 1
  | 'AVAILABLE'
  | 'BOOKED'
  | 'LOCKED'
  | 'CHECKED_OUT'
  | 'INCIT'
  | 'NOT_RENTABLE'
  | 'created'
  | 'update'
  | 'deleted'
  | string
  | number
}

export function StatusLabel(props: StatusLabelProps): JSX.Element {
  let label = '';
  let color = '';
  switch (props.status) {
    case 0:
      label = 'Avslutad';
      color = colors.neutrals['700']
      break;
    case 1:
      label = 'Aktiv';
      color = colors.system.success['900'];
      break;
    case 'AVAILABLE':
      label = 'Ledig';
      color = colors.system.success['900'];
      break;
    case 'PAYED':
      label = 'Betald';
      color = colors.system.success['900'];
      break;
    case 'BOOKED':
      label = 'Bokad';
      color = colors.primary['900'];
      break;
    case 'CHECKED_OUT':
      label = 'Väntar Klarnaflytt';
      color = colors.primary['900'];
      break;
    case 'INCIT':
      label = 'Incit';
      color = colors.primary['900'];
      break;
      case 'NOT_RENTABLE':
      label = 'Ej uthyrbart';
      color = colors.primary['900'];
      break;
    case 'LOCKED':
      label = 'Låst';
      color = colors.system.error['900'];
      break;
    case 'created':
      label = 'Skapad';
      color = colors.system.success['900'];
      break;
    case 'update':
      label = 'Uppdaterad';
      color = colors.primary['900'];
      break;
    case 'deleted':
      label = 'Borttagen';
      color = colors.system.error['900'];
      break;

    default:
      color = 'transparent';
      break;
  }
  return (
    <Tag w={'85px'} py={1} variant="solid" backgroundColor={color} fontSize={12} justifyContent="center">
      {label}
    </Tag>
  );
}
