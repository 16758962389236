import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { colors } from 'lib/constants';
import React from 'react';
import { DayPicker, DayPickerProps } from 'react-day-picker';

import 'react-day-picker/dist/style.css';

export default function DatePicker({
  children,
  dayPickerProps,
  disabled = false,
  minW = true
}: {
  disabled?: boolean,
  dayPickerProps: DayPickerProps
  children: React.ReactNode
  minW?: boolean;
}): JSX.Element {
  const modifiersStyles = {
    selected: {
      backgroundColor: colors.primary[900],
      borderRadius: 50,
    },
    day: {
      color: 'green',
    },
    today: {
      color: 'black',
    },
    container: {
      backgroundColor: 'red',
    },
  };

  return (
    <Popover>
      {(state) => (
        <>
          <PopoverTrigger>
            <Button
              minW={minW ? '250px' : 'unset'}
              disabled={disabled}
              marginTop={1}
              variant="secondary"
              fontWeight="normal"
              outlineColor={colors.primary[900]}
              rightIcon={<ChevronDownIcon />}>
              {children}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverBody px="0">
                <DayPicker
                  {...dayPickerProps}
                  modifiersStyles={modifiersStyles}
                />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )
      }
    </Popover >
  );
}

export const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];
const WEEKDAYS_LONG = [
  'Söndag',
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
];
const WEEKDAYS_SHORT = ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'];
