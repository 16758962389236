import React, { FormEvent } from 'react';
import firebase from 'firebase/app';
import logo from 'static/images/logo.svg';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
  Box,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useFirebaseUser } from 'lib/useFirebaseUser';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { SearchIcon } from '@chakra-ui/icons';
import { MoreIcon, OrdersIcon, PersonIcon } from 'lib/IconLib';
import { Icon } from './Icon';
import { useApi } from 'lib/useApi';
import { colors } from 'lib/constants';

export function PageHeader(): JSX.Element {
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const { data: stat } = useApi<{ currentNotification: number }>('/statistics');
  const history = useHistory();
  const location = useLocation();
  const isStaging = process.env.REACT_APP_ENV === 'staging';

  function logout(): void {
    firebase.auth().signOut();
  }

  React.useEffect(() => {
    if (location.pathname !== routes.search.path && searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, [location.pathname]);

  function onSearch(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (location.pathname === routes.search.path) return; // uses onChange instead

    const query = searchInputRef.current?.value.trim();

    history.push({
      pathname: routes.search.path,
      search: `?q=${query}`,
    });
  }

  function onSearchInput(event: FormEvent<HTMLInputElement>): void {
    if (location.pathname !== routes.search.path) return;

    const query = event?.currentTarget.value.trim();

    history.replace({
      pathname: routes.search.path,
      search: `?q=${query}`,
    });
  }

  React.useEffect(() => {
    return document.addEventListener('keydown', function (event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        searchInputRef.current?.focus();
        searchInputRef.current?.select();
      }
    });
  }, []);

  return (
    <Flex
      className="page-header"
      flexDir="row"
      justifyContent="space-between"
      style={{ top: isStaging ? '30px' : 0 }}>
      <Logo />

      <Flex align="center">
        <Box
          display={{
            base: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          }}>
          <form onSubmit={onSearch}>
            <InputGroup className="search-input" mr={4} pl={2}>
              <InputRightElement
                className="search-input-icon"
                children={<SearchIcon boxSize="1.2em" />}
              />
              <Input
                ref={searchInputRef}
                type="search"
                onChange={onSearchInput}
                placeholder="Sök (cmd+k / ctrl+k)"
              />
            </InputGroup>
          </form>
        </Box>

        <Button
          ml={4}
          border="none"
          padding="0"
          backgroundColor={
            stat?.currentNotification ? colors.system.error[500] : ''
          }
          variant="outline"
          onClick={() => {
            history.push('/notifieringar');
          }}>
          <Icon icon={OrdersIcon} fill="#000" />
        </Button>
        <Menu>
          <HeaderMenuButton />
          <MenuList>
            <MenuGroup>
              <MenuItem onClick={logout}>Logga ut</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <MobileMenu />
      </Flex>
    </Flex>
  );
}

function HeaderMenuButton(): JSX.Element {
  const currentUser = useFirebaseUser();

  return (
    <MenuButton className="menu" mx={4}>
      <Flex align="center">
        <Box className="svg-wrapper">
          <PersonIcon width="1.8em" height="1.8em" fill="black" />
        </Box>
        <Text
          fontSize="sm"
          display={{
            base: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          }}>
          {currentUser?.displayName || currentUser?.email}
        </Text>
        <Box
          className="svg-wrapper"
          display={{
            base: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          }}>
          <MoreIcon width="1.2em" height="1.2em" fill="black" />
        </Box>
      </Flex>
    </MenuButton>
  );
}

function Logo(): JSX.Element {
  const history = useHistory();
  return (
    <Flex
      flexDir="row"
      align="center"
      _hover={{ cursor: 'pointer' }}
      onClick={() => {
        history.push('/');
      }}>
      <img src={logo} alt="mittlager logga" />
      <Text
        display={['none', 'none', 'block']}
        fontSize="xl"
        ml={4}
        className="header">
        Portalen
      </Text>
    </Flex>
  );
}

function MobileMenu(): JSX.Element {
  return (
    <Button
      className="mobile-menu-toggle"
      variant="outline"
      ml={4}
      onClick={(): boolean =>
        document.body.classList.toggle('show-mobile-menu')
      }>
      Meny
    </Button>
  );
}
