import { BoxProps, Flex, } from '@chakra-ui/react';
import React from 'react';
import Statistics from 'style/statistics.module.css';

interface CardProps extends BoxProps {
  title?: string;
  number: number;
  fontSize?: number;
}

export function NumberCard(props: CardProps): JSX.Element {
  return (
    <Flex
      className={Statistics.card}
      {...props}
      flex="1"
      flexDir="column"
      justify="center"
      align="center"
      minH="235px"
    >
      {props.title && <span className={Statistics.cardTitle}>{props.title}</span>}
      <span style={{ fontSize: props.fontSize || "42px" }} className={Statistics.cardNumber}>{props.number}</span>
    </Flex>
  );
}
