import React from 'react';
import { Campaign } from 'models/ApiModels';
import { useParams } from 'react-router-dom';
import { useApi } from 'lib/useApi';
import { AddCampaignsPage } from './AddCampaignPage';

export const EditCampaignpage = (): JSX.Element => {
    const params = useParams<{ id: string }>();
    const res = useApi<Campaign>(`/campaign/${params.id}`);

    return (
        <>
            {res && res.data && <AddCampaignsPage campaign={res.data as Campaign} mode="edit" />}
        </>
    )
}
