import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Sidebar } from './components/Sidebar/Sidebar';
import './style/base.css';
import './style/page-header.css';
import { MainContentContainer } from 'components/MainContentContainer';
import { PageHeader } from 'components/PageHeader';
import { PortalContextProvider } from 'PortalContext';
import { Flex } from '@chakra-ui/react';

export function PortalRoot(): JSX.Element {
  return (
    <PortalContextProvider>
      <Router>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          flex="1"
          mt={{ base: '60px', md: 0 }}>
          <PageHeader />
          <Sidebar />
          <MainContentContainer />
        </Flex>
      </Router>
    </PortalContextProvider>
  );
}
