import { Box, Flex, FormLabel, Switch, useToast, Button } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from 'lib/useApi';
import Statistics from 'style/statistics.module.css';
import { fetchApi } from 'lib/fetchApi';
import { Order, User, UserRoles } from 'models/ApiModels';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { orderTable } from 'components/DataTable/DataTableModels';
import { DataTable } from 'components/DataTable/DataTable';
import { EmailIcon } from '@chakra-ui/icons';

export function UserPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: user, setData: setUserData } = useApi<User>(`/users/${id}`);
  const { data: userOrders } = useApi<Order[]>(`/orders/user/${id}`);
  const toast = useToast();

  async function onToggleAdminRole(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!user) return;

    const prevVal = user.roles[UserRoles.PortalAdmin];

    setAdminRole(e.target.checked);

    const path =
      (e.target.checked ? '/users/addrole' : '/users/removerole') +
      `?userId=${user.id}&role=portal:admin`;
    const resp = await fetchApi(path);

    if (!resp.ok) {
      const respData = await resp.json();

      setAdminRole(prevVal);
      toast({
        title: 'Fel',
        description: respData.message,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }

  function setAdminRole(value: boolean): void {
    if (!user) return;

    setUserData({
      ...user,
      roles: {
        ...user.roles,
        [UserRoles.PortalAdmin]: value,
      },
    });
  }

  async function onToggleSecuritasRole(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!user) return;

    const prevVal = user.roles[UserRoles.SecuritasAdmin];

    setSecuritasRole(e.target.checked);

    const path =
      (e.target.checked ? '/users/addrole' : '/users/removerole') +
      `?userId=${user.id}&role=portal:securitas`;
    const resp = await fetchApi(path);

    if (!resp.ok) {
      const respData = await resp.json();

      setSecuritasRole(prevVal);
      toast({
        title: 'Fel',
        description: respData.message,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }

  function setSecuritasRole(value: boolean): void {
    if (!user) return;

    setUserData({
      ...user,
      roles: {
        ...user.roles,
        [UserRoles.SecuritasAdmin]: value,
      },
    });
  }

  return (
    <>
      <ContainerHead title="Kund" backButton={true}>
        <Box
          display="flex"
          flexDirection="column"
          className={Statistics.invisibleCard}>
          <Box display="flex" flexDirection="column">
            <span className={Statistics.cardBigTitle}>E-Post</span>
            <span className={Statistics.cardDesc}>
              {user?.email ?? 'Ingen email'}
            </span>
          </Box>

        </Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box color="white">
          <Button variant="primary" onClick={() => window.location.href = `mailto:${user?.email}?subject="Från mittlager: Hej!"`}><EmailIcon h={3} w={3} mr={3} />Kontakta</Button>
        </Box>
      </ContainerHead>

      <Container>
        {/* <Flex direction="row">
          <IconCard
            title="Kontakta kund"
            icon={userIcon}
            desc="Maila"
            link={`mailto:${user?.email}?subject="Från mittlager: Hej!"`}
          />
        </Flex> */}

        <Flex align="center" marginY={10}>
          <Switch
            id="admin-toggle"
            size="lg"
            onChange={onToggleAdminRole}
            isChecked={user?.roles['portal:admin'] || false}
          />
          <FormLabel ml={4} htmlFor="admin-toggle">
            Portaladministratör
          </FormLabel>
        </Flex>
        <Flex align="center" marginY={10}>
          <Switch
            id="securitas-toggle"
            size="lg"
            onChange={onToggleSecuritasRole}
            isChecked={user?.roles['portal:securitas'] || false}
          />
          <FormLabel ml={4} htmlFor="securitas-toggle">
            Securitasadministratör
          </FormLabel>
        </Flex>
        <DataTable
          showSearch={false}
          title="Ordrar"
          tableDef={orderTable}
          data={userOrders}
          searchableFields={['unit.facility.name', 'createdAt']}
        />
      </Container>
    </>
  );
}
